import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';
import { Params } from 'api/types/params';
import api from 'dataService/api';
import { PaginatedResponse } from 'types/pagination.types';
import { addFullNameToDataItems } from 'util/addFullNameToDataItems';

type PartnerOpps = 'current' | 'potential';

export const PARTNER_KEYS = {
  all: ['partners'] as const,
  list: (params: Params) => [...PARTNER_KEYS.all, 'list', params] as const,
  opps: (type: PartnerOpps) => [...PARTNER_KEYS.all, 'opps', type] as const,
  oppsList: (type: PartnerOpps, params: Params) =>
    [...PARTNER_KEYS.opps(type), 'list', params] as const,
  recruiters: (partnerId: string | number | null, params: Params = {}) => {
    return [...PARTNER_KEYS.all, 'recruiters', partnerId, params] as const;
  },
};

export const PARTNER_QUERY_OPTIONS = {
  detail: (id: string) => {
    return queryOptions({
      queryKey: [...PARTNER_KEYS.all, id] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get(`/partners/${id}`, {
          signal,
        });

        return response.data;
      },
    });
  },
  opportunities: (type: PartnerOpps, params = {}) => {
    return queryOptions({
      queryKey: PARTNER_KEYS.oppsList(type, params),
      queryFn: async ({ signal }) => {
        const response = await api.get(`/partners/opportunities/${type}`, {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
  types: () => {
    return queryOptions({
      queryKey: [...PARTNER_KEYS.all, 'types'] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get('/partners/types', {
          signal,
          params: {
            limit: 20,
          },
        });

        return response.data;
      },
    });
  },
  infinitePartnersList: (params: { [key: string]: unknown }) => {
    const { limit = 20, orderBy = 'name', orderDir = 'ASC' } = params ?? {};

    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get<PaginatedResponse<unknown>>(
          '/partners',
          {
            params: {
              ...params,
              limit,
              skip: (pageParam - 1) * (limit as number),
              orderBy,
              orderDir,
            },
            signal,
          }
        );

        return response.data;
      },
      queryKey: PARTNER_KEYS.list({
        params: {
          ...params,
          limit,
          orderBy,
          orderDir,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
    });
  },
  infiniteRecruitersList: (partnerId: number | null) => {
    return (params: { [key: string]: unknown }) => {
      const { limit = 20, orderBy = 'name', orderDir = 'ASC' } = params ?? {};

      return infiniteQueryOptions({
        queryFn: async ({ pageParam = 1, signal }) => {
          const response = await api.get<
            PaginatedResponse<{
              id: number;
              firstName: string;
              lastName: string;
              email: string;
              headshot?: {
                location: string;
              };
            }>
          >(`/partners/${partnerId}/recruiters`, {
            params: {
              ...params,
              limit,
              skip: (pageParam - 1) * (limit as number),
              orderBy,
              orderDir,
            },
            signal,
          });

          return response.data;
        },
        queryKey: PARTNER_KEYS.recruiters(partnerId, {
          params: {
            ...params,
            limit,
            orderBy,
            orderDir,
          },
        }),
        initialPageParam: 1,
        getNextPageParam: (lastPage, _, lastPageParam) => {
          if (lastPage.items.length < (limit as number)) {
            return undefined;
          }

          return lastPageParam + 1;
        },
        select: addFullNameToDataItems,
      });
    };
  },
};
