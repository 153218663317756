import { Popover } from '@radix-ui/react-popover';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { ComboboxData } from 'components/ComboboxField/types/ComboboxField.types';
import { ListLoadMore } from 'components/ListLoadMore';
import {
  ComboboxCheck,
  ComboboxContent,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
  ComboboxTrigger,
} from 'components/ds/Combobox';
import { Spinner } from 'components/ds/Spinner';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import * as fuzzy from 'fuzzy';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

type AsyncComboboxFieldProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<ControllerRenderProps<TFieldValues>, 'value' | 'ref'> & {
    value?: ComboboxData | null;
    displayName: string;
    onComboSelect?: (value: ComboboxData | null) => void;
    infiniteQueryOptions: (params: { [key: string]: unknown }) => any;
    enableCreate?: {
      onCreate?: (name: string) => void;
      getSelectionValue: (name: string) => ComboboxData;
    };
  };

export const AsyncComboboxField = React.forwardRef<
  React.ElementRef<typeof ComboboxTrigger>,
  AsyncComboboxFieldProps
>(
  (
    {
      displayName,
      infiniteQueryOptions,
      value,
      onChange,
      onBlur,
      onComboSelect,
      enableCreate,
      ...rest
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    const nameLowercase = displayName.toLowerCase();

    return (
      <Popover
        open={open}
        onOpenChange={(open) => {
          setOpen(open);

          if (!open) {
            onBlur();
          }
        }}
      >
        <ComboboxTrigger
          {...rest}
          aria-placeholder={
            value?.name == null ? `Select ${nameLowercase}...` : undefined
          }
          ref={ref}
        >
          {value?.name ?? `Select ${nameLowercase}...`}
        </ComboboxTrigger>
        <ComboboxContent shouldFilter={false}>
          <React.Suspense
            fallback={
              <div className="grid h-[300px] place-items-center">
                <Spinner size="sm" />
              </div>
            }
          >
            <AsyncComboboxList
              label={nameLowercase}
              infiniteQueryOptions={infiniteQueryOptions}
              selection={value ?? null}
              onItemSelect={(item) => {
                onChange(item);
                onComboSelect?.(item);
                setOpen(false);
              }}
              enableCreate={enableCreate}
            />
          </React.Suspense>
        </ComboboxContent>
      </Popover>
    );
  }
);
AsyncComboboxField.displayName = 'AsyncComboboxField';

function AsyncComboboxList({
  label,
  infiniteQueryOptions,
  selection,
  onItemSelect,
  enableCreate,
}: {
  label: string;
  infiniteQueryOptions: (params: { [key: string]: unknown }) => any;
  selection: ComboboxData | null;
  onItemSelect: (value: ComboboxData | null) => void;
  enableCreate?: {
    onCreate?: (name: string) => void;
    getSelectionValue: (name: string) => ComboboxData;
  };
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [search, setSearch] = React.useState('');
  const debouncedSearchTerm = useDebounce(search, 500);
  const params = React.useMemo(() => {
    return debouncedSearchTerm ? { term: debouncedSearchTerm } : {};
  }, [debouncedSearchTerm]);
  const deferredParams = React.useDeferredValue(params);
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useSuspenseInfiniteQuery(infiniteQueryOptions(deferredParams));
  const isDeferred = params !== deferredParams;

  const filteredAndSortedData = React.useMemo(() => {
    const flattenedData = data.pages.flatMap((page: any) => {
      return page.items;
    });
    if (!selection || !fuzzy.match(debouncedSearchTerm, selection.name)) {
      return flattenedData;
    }

    // Sort the data so that the selected item is always at the top
    return [
      selection,
      ...flattenedData.filter((val) => val.id !== selection.id),
    ];
  }, [data, selection, debouncedSearchTerm]);

  const { ref: loadMoreRef, inView: loadMoreInView } = useInView();
  React.useEffect(() => {
    if (loadMoreInView && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, loadMoreInView]);

  React.useEffect(() => {
    if (inputRef.current) {
      /**
       * Need to focus the input when the component mounts because the component
       * could initially suspend.
       */
      inputRef.current.focus();
    }
  }, []);

  function createItem(name: string) {
    if (enableCreate) {
      enableCreate.onCreate?.(name);
    }
  }

  return (
    <React.Fragment>
      <ComboboxInput
        ref={inputRef}
        loading={isDeferred}
        placeholder={`Search ${label}...`}
        onValueChange={(value) => {
          setSearch(value);
        }}
      />
      <ComboboxList>
        {enableCreate && search.length > 0 && (
          <ItemCreate
            inputValue={search}
            label={label}
            onSelect={() => {
              onItemSelect(enableCreate.getSelectionValue(search));
              createItem(search);
            }}
          />
        )}
        {filteredAndSortedData.map((item) => {
          const isSelected = selection?.id === item.id;

          return (
            <ComboboxItem
              key={item.id}
              value={item.id.toString()}
              data-checked={isSelected}
              onSelect={() => {
                onItemSelect(isSelected ? null : item);
              }}
            >
              {item.ui ?? item.name}
              <ComboboxCheck checked={isSelected} />
            </ComboboxItem>
          );
        })}
        {hasNextPage && (
          <ListLoadMore loading={isFetchingNextPage} ref={loadMoreRef} />
        )}
        {filteredAndSortedData.length === 0 &&
          !isDeferred &&
          enableCreate == null && (
            <div className="py-6 text-center text-sm text-ds-text-primary">
              No results
            </div>
          )}
      </ComboboxList>
    </React.Fragment>
  );
}

function ItemCreate({
  onSelect,
  inputValue,
  label,
}: {
  inputValue: string;
  label: string;
  onSelect: () => void;
}) {
  return (
    <ComboboxItem
      key={inputValue}
      value={inputValue}
      onSelect={() => {
        onSelect();
      }}
      className="!text-ds-text-secondary"
    >
      <span className="flex gap-2">
        <Icon className="h-5 w-4">
          <IconUse id="add-fill" />
        </Icon>
        <span className="text-sm">
          Create {label} &quot;{inputValue}&quot;
        </span>
      </span>
    </ComboboxItem>
  );
}
