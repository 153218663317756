import type { QueryClient } from '@tanstack/react-query';
import { USER_OPTIONS } from 'api/user/user';
import { USER_ACCESS, USER_TYPES } from 'api/user/user.constants';
import {
  NestedRouteErrorHandler,
  TopLevelRouteErrorHandler,
} from 'components/Error';
import { NotFound } from 'components/NotFound';
import { SocketProvider } from 'components/SocketProvider';
import { LoadingContainer } from 'components/ds/Spinner';
import { PrivateAppLayout } from 'components/layout-v2/PrivateAppLayout';
import { getIsUtahParticipant } from 'modules/participant/utah/helpers/getIsUtahParticipant.helpers';
import {
  UTAH_PATHS,
  UTAH_PROGRAM_AVAILABLE_STEPS,
} from 'modules/participant/utah/utah.constants';
import {
  maybeAccessStep,
  maybeRedirectToLatestStep,
} from 'modules/participant/utah/utah.helpers';
import { savedLoader } from 'modules/participant/utah/views/Saved';
import { utahOnboardingBasicInfoLoader } from 'modules/participant/utah/views/UtahOnboardingBasicInfo';
import { pipelineLoader } from 'modules/team/pipeline/views/Pipeline';
import { PostHog } from 'posthog-js';
import * as React from 'react';
import { Outlet, RouteObject, redirect } from 'react-router-dom';
import { lazyRetry } from 'util/lazyRetry';
import { notFound } from 'util/notFound';
import { authenticationGuardLoader } from 'views/guards/authentication.guard';
import { onboardedGuardLoader } from 'views/guards/onboarded.guard';
import { userAccessGuardLoader } from 'views/guards/userAccess.guard';
import { userTypeGuardLoader } from 'views/guards/userType.guard';
import { twoFactorAuthLoader } from 'views/private/account/TwoFactorAuth';
import { interviewLoader } from 'views/private/interviews/EditInterview';
import { jobLoader } from 'views/private/jobs/Job';
import { jobEditStatusesLoader } from 'views/private/jobs/JobEditStatuses';
import { jobInterviewsLoader } from 'views/private/jobs/JobInterviews';
import { jobParticipantGamePlansLoader } from 'views/private/jobs/JobParticipantGamePlans';
import { participantLoader } from 'views/private/participants/Participant';

export function PrivateRoutes(
  queryClient: QueryClient,
  posthogClient?: PostHog
): RouteObject[] {
  return [
    {
      path: '*',
      loader: authenticationGuardLoader,
      errorElement: <TopLevelRouteErrorHandler />,
      element: (
        <React.Suspense fallback={<LoadingContainer level="full" />}>
          <SocketProvider>
            <Outlet />
          </SocketProvider>
        </React.Suspense>
      ),
      children: [
        {
          path: '*',
          loader: () => {
            return onboardedGuardLoader(queryClient);
          },
          element: <Outlet />,
          children: [
            {
              path: '*',
              errorElement: <TopLevelRouteErrorHandler />,
              element: (
                <PrivateAppLayout>
                  <React.Suspense fallback={<LoadingContainer level="page" />}>
                    <Outlet />
                  </React.Suspense>
                </PrivateAppLayout>
              ),
              children: [
                {
                  index: true,
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Home } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Home" */
                          'views/private/Home'
                        ),
                      'Home'
                    );
                    return {
                      Component: Home,
                    };
                  },
                },
                {
                  path: 'notifications',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Notifications } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Notifications" */
                          'views/private/notifications/Notifications'
                        ),
                      'Notifications'
                    );
                    return {
                      Component: Notifications,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { NotificationsList } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "NotificationsList" */
                              'views/private/notifications/NotificationsList'
                            ),
                          'NotificationsList'
                        );
                        return {
                          Component: NotificationsList,
                        };
                      },
                    },
                    {
                      path: 'tasks',
                      errorElement: <NestedRouteErrorHandler />,
                      loader: () => {
                        return userTypeGuardLoader(
                          [USER_TYPES.breakline],
                          queryClient
                        );
                      },
                      lazy: async () => {
                        const { NotificationsTasks } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "NotificationsTasks" */
                              'views/private/notifications/NotificationsTasks'
                            ),
                          'NotificationsTasks'
                        );
                        return {
                          Component: NotificationsTasks,
                        };
                      },
                    },
                    {
                      path: 'outgoing',
                      errorElement: <NestedRouteErrorHandler />,
                      loader: () => {
                        return userTypeGuardLoader(
                          [USER_TYPES.breakline],
                          queryClient
                        );
                      },
                      lazy: async () => {
                        const { NotificationsOutgoing } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "NotificationsOutgoing" */
                              'views/private/notifications/NotificationsOutgoing'
                            ),
                          'NotificationsOutgoing'
                        );
                        return {
                          Component: NotificationsOutgoing,
                        };
                      },
                    },
                    {
                      path: 'manager',
                      errorElement: <NestedRouteErrorHandler />,
                      loader: () => {
                        return Promise.all([
                          userAccessGuardLoader(
                            [USER_ACCESS.admin],
                            queryClient
                          ),
                          userTypeGuardLoader(
                            [USER_TYPES.breakline],
                            queryClient
                          ),
                        ]);
                      },
                      lazy: async () => {
                        const { NotificationsManager } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "NotificationsManager" */
                              'views/private/notifications/NotificationsManager'
                            ),
                          'NotificationsManager'
                        );
                        return {
                          Component: NotificationsManager,
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'jobs',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Jobs } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Jobs" */
                          'views/private/jobs/Jobs'
                        ),
                      'Jobs'
                    );
                    return {
                      Component: Jobs,
                    };
                  },
                },
                {
                  path: 'jobs/create',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { CreateJobView: CreateJob } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "CreateJob" */
                          'views/private/jobs/CreateJobView'
                        ),
                      'CreateJob'
                    );
                    return {
                      Component: CreateJob,
                    };
                  },
                },
                {
                  path: 'jobs/:jobId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: async (args) => {
                    await userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                    return jobLoader(args, queryClient);
                  },
                  lazy: async () => {
                    const { Job } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Job" */
                          'views/private/jobs/Job'
                        ),
                      'Job'
                    );
                    return {
                      Component: Job,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      loader: async (args) => {
                        return jobParticipantGamePlansLoader(queryClient, args);
                      },
                      // We're using react query to handle the updates and don't want to re-run the loader when search params change
                      shouldRevalidate: () => false,
                      lazy: async () => {
                        const { JobParticipantGamePlans } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "JobParticipantGamePlans" */
                              'views/private/jobs/JobParticipantGamePlans'
                            ),
                          'JobParticipantGamePlans'
                        );
                        return {
                          element: <JobParticipantGamePlans />,
                        };
                      },
                    },
                    {
                      path: 'interviews',
                      errorElement: <NestedRouteErrorHandler />,
                      loader: (args) => {
                        return jobInterviewsLoader(queryClient, args);
                      },
                      shouldRevalidate: () => false,
                      lazy: async () => {
                        const { JobInterviews } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "JobInterviews" */
                              'views/private/jobs/JobInterviews'
                            ),
                          'JobInterviews'
                        );
                        return {
                          element: <JobInterviews />,
                        };
                      },
                    },
                    {
                      path: 'edit',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { EditJob } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "EditJob" */
                              'views/private/jobs/EditJob'
                            ),
                          'EditJob'
                        );
                        return {
                          element: (
                            <React.Suspense
                              fallback={<LoadingContainer level="component" />}
                            >
                              <EditJob />
                            </React.Suspense>
                          ),
                        };
                      },
                    },
                    {
                      path: 'insight',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { JobInsight } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "JobInsight" */
                              'views/private/jobs/JobInsight'
                            ),
                          'JobInsight'
                        );
                        return {
                          element: (
                            <React.Suspense
                              fallback={<LoadingContainer level="component" />}
                            >
                              <JobInsight />
                            </React.Suspense>
                          ),
                        };
                      },
                    },
                    {
                      path: 'edit/statuses',
                      errorElement: <NestedRouteErrorHandler />,
                      loader: (args) => {
                        return jobEditStatusesLoader(queryClient, args);
                      },
                      shouldRevalidate: () => false,
                      lazy: async () => {
                        const { JobEditStatuses } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "JobEditStatuses" */
                              'views/private/jobs/JobEditStatuses'
                            ),
                          'JobEditStatuses'
                        );
                        return {
                          element: <JobEditStatuses />,
                        };
                      },
                    },
                  ],
                },
                // SAVED
                {
                  path: 'saved',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Saved } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Saved" */
                          'modules/participant/utah/views/Saved'
                        ),
                      'Account'
                    );

                    return {
                      loader: (args) => {
                        return savedLoader(args, queryClient, posthogClient);
                      },
                      Component: Saved,
                    };
                  },
                },
                {
                  path: 'account',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Account } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Account" */
                          'views/private/account/Account'
                        ),
                      'Account'
                    );
                    return {
                      element: <Account />,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { AccountPreferences } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AccountPreferences" */
                              'views/private/account/AccountPreferences'
                            ),
                          'AccountPreferences'
                        );
                        return {
                          element: <AccountPreferences />,
                        };
                      },
                    },
                    {
                      path: 'notifications',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { NotificationsPreferences } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "NotificationsPreferences" */
                              'views/private/account/NotificationsPreferences'
                            ),
                          'NotificationsPreferences'
                        );
                        return {
                          element: <NotificationsPreferences />,
                        };
                      },
                    },
                    {
                      path: 'skills',
                      errorElement: <NestedRouteErrorHandler />,
                      loader: () => {
                        return userTypeGuardLoader(
                          [USER_TYPES.participant],
                          queryClient
                        );
                      },
                      lazy: async () => {
                        const { AccountSkills } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AccountSkills" */
                              'views/private/account/AccountSkills'
                            ),
                          'AccountSkills'
                        );
                        return {
                          Component: AccountSkills,
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'account/two-factor-authentication/new',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return twoFactorAuthLoader(queryClient);
                  },
                  lazy: async () => {
                    const { TwoFactorAuth } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "TwoFactorAuth" */
                          'views/private/account/TwoFactorAuth'
                        ),
                      'TwoFactorAuth'
                    );
                    return {
                      element: <TwoFactorAuth />,
                    };
                  },
                },
                // INTERVIEWS
                {
                  path: 'interviews',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.participant],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { Interviews } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Interviews" */
                          'views/private/interviews/Interviews'
                        ),
                      'Interviews'
                    );
                    return {
                      Component: Interviews,
                    };
                  },
                },
                {
                  path: 'interviews/:interviewId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline, USER_TYPES.participant],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { Interview } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Interview" */
                          'views/private/interviews/Interview'
                        ),
                      'Interview'
                    );
                    return {
                      Component: Interview,
                    };
                  },
                },
                {
                  path: 'interviews/:interviewId/edit',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: async (args) => {
                    await userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );

                    return interviewLoader(queryClient, args);
                  },
                  lazy: async () => {
                    const { EditInterview } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EditInterview" */
                          'views/private/interviews/EditInterview'
                        ),
                      'EditInterview'
                    );
                    return {
                      Component: EditInterview,
                    };
                  },
                },
                {
                  path: 'interviews/create',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline, USER_TYPES.participant],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { CreateInterview } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "CreateInterview" */
                          'views/private/interviews/CreateInterview'
                        ),
                      'CreateInterview'
                    );
                    return {
                      Component: CreateInterview,
                    };
                  },
                },
                // PLAYGROUND
                {
                  path: 'playground',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userAccessGuardLoader(
                      [USER_ACCESS.admin],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { Playground } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Playground" */
                          'views/private/playground/Playground'
                        ),
                      'Playground'
                    );

                    return {
                      Component: Playground,
                    };
                  },
                },
                // PIPELINE
                {
                  path: 'pipeline-plus',
                  loader: async (args) => {
                    await userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );

                    return pipelineLoader(queryClient, args);
                  },
                  shouldRevalidate: () => false,
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Pipeline } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Pipeline" */
                          'modules/team/pipeline/views/Pipeline'
                        ),
                      'Pipeline'
                    );

                    return {
                      Component: Pipeline,
                    };
                  },
                  children: [
                    {
                      index: true,
                      shouldRevalidate: () => false,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { Roles } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "Roles" */
                              'modules/team/pipeline/views/Roles'
                            ),
                          'Roles'
                        );

                        return {
                          Component: Roles,
                        };
                      },
                    },
                    {
                      path: 'interviews',
                      shouldRevalidate: () => false,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { Interviews } = await lazyRetry(
                          () =>
                            /* webpackChunkName: "Interviews" */
                            import('modules/team/pipeline/views/Interviews'),
                          'Interviews'
                        );

                        return {
                          Component: Interviews,
                        };
                      },
                    },
                  ],
                },
                // EVENTS
                {
                  path: 'events',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Events } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Events" */
                          'views/private/events/Events'
                        ),
                      'Events'
                    );
                    return {
                      Component: Events,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { EventType } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "EventType" */
                              'views/private/events/EventType'
                            ),
                          'EventType'
                        );
                        return {
                          Component: EventType,
                        };
                      },
                    },
                    {
                      path: 'type/:eventTypeId',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { EventType } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "EventType" */
                              'views/private/events/EventType'
                            ),
                          'EventType'
                        );
                        return {
                          Component: EventType,
                        };
                      },
                    },
                    {
                      path: 'type/all',
                      loader: () => {
                        return redirect('/events');
                      },
                    },
                  ],
                },
                {
                  path: 'events/:eventId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Event } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Event" */
                          'views/private/events/Event'
                        ),
                      'Event'
                    );
                    return {
                      Component: Event,
                    };
                  },
                },
                {
                  path: 'events/create',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { CreateEvent } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "CreateEvent" */
                          'views/private/events/EditEvent'
                        ),
                      'CreateEvent'
                    );
                    return {
                      Component: CreateEvent,
                    };
                  },
                },
                {
                  path: 'events/:eventId/edit',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { EditEvent } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EditEvent" */
                          'views/private/events/EditEvent'
                        ),
                      'EditEvent'
                    );
                    return {
                      Component: EditEvent,
                    };
                  },
                },
                {
                  path: 'events/:dupEventId/create',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { DuplicateEvent } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "DuplicateEvent" */
                          'views/private/events/EditEvent'
                        ),
                      'DuplicateEvent'
                    );
                    return {
                      Component: DuplicateEvent,
                    };
                  },
                },
                // ASSIGNMENTS
                {
                  path: 'assignments',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline, USER_TYPES.participant],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { ListAssignments } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "ListAssignments" */
                          'views/private/assignments/Assignments'
                        ),
                      'ListAssignments'
                    );
                    return {
                      Component: ListAssignments,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { UserAssignmentsList } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "UserAssignmentsList" */
                              'views/private/assignments/UserAssignmentsList'
                            ),
                          'UserAssignmentsList'
                        );
                        return {
                          Component: UserAssignmentsList,
                        };
                      },
                    },
                    {
                      path: 'pending',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { PendingUserAssignmentsList } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "PendingUserAssignmentsList" */
                              'views/private/assignments/UserAssignmentsList'
                            ),
                          'PendingUserAssignmentsList'
                        );
                        return {
                          element: (
                            <PendingUserAssignmentsList type="pending" />
                          ),
                        };
                      },
                    },
                    {
                      path: 'submitted',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { SubmittedUserAssignmentsList } =
                          await lazyRetry(
                            () =>
                              import(
                                /* webpackChunkName: "SubmittedUserAssignmentsList" */
                                'views/private/assignments/UserAssignmentsList'
                              ),
                            'SubmittedUserAssignmentsList'
                          );
                        return {
                          element: (
                            <SubmittedUserAssignmentsList type="submitted" />
                          ),
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'assignments/create',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { CreateAssignment } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "CreateAssignment" */
                          'views/private/assignments/EditAssignment'
                        ),
                      'CreateAssignment'
                    );
                    return {
                      Component: CreateAssignment,
                    };
                  },
                },
                {
                  path: 'assignments/:assignmentId/edit',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { EditAssignment } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EditAssignment" */
                          'views/private/assignments/EditAssignment'
                        ),
                      'EditAssignment'
                    );
                    return {
                      Component: EditAssignment,
                    };
                  },
                },
                {
                  path: 'assignments/:assignmentId/preview',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { PreviewAssignment } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "PreviewAssignment" */
                          'views/private/assignments/PreviewAssignment'
                        ),
                      'PreviewAssignment'
                    );
                    return {
                      Component: PreviewAssignment,
                    };
                  },
                },
                {
                  path: 'assignments/:assignmentId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Assignment } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Assignment" */
                          'views/private/assignments/Assignment'
                        ),
                      'Assignment'
                    );
                    return {
                      Component: Assignment,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      loader: () => {
                        return userTypeGuardLoader(
                          [USER_TYPES.breakline],
                          queryClient
                        );
                      },
                      lazy: async () => {
                        const { AssignmentList } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AssignmentList" */
                              'views/private/assignments/AssignmentList'
                            ),
                          'AssignmentList'
                        );
                        return {
                          Component: AssignmentList,
                        };
                      },
                    },
                    {
                      path: 'submissions',
                      errorElement: <NestedRouteErrorHandler />,
                      loader: () => {
                        return userTypeGuardLoader(
                          [USER_TYPES.breakline],
                          queryClient
                        );
                      },
                      lazy: async () => {
                        const { AssignmentList } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AssignmentList" */
                              'views/private/assignments/AssignmentList'
                            ),
                          'AssignmentList'
                        );
                        return {
                          element: <AssignmentList type="submissions" />,
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'assignments/submissions/:submissionId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline, USER_TYPES.participant],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { AssignmentSubmission } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "AssignmentSubmission" */
                          'views/private/assignments/AssignmentSubmission'
                        ),
                      'AssignmentSubmission'
                    );
                    return {
                      Component: AssignmentSubmission,
                    };
                  },
                },
                // SURVEYS
                {
                  path: 'surveys',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { Surveys } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Surveys" */
                          'views/private/surveys/Surveys'
                        ),
                      'Surveys'
                    );
                    return {
                      Component: Surveys,
                    };
                  },
                },
                {
                  path: 'surveys/create',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { CreateSurvey } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "CreateSurvey" */
                          'views/private/surveys/EditSurvey'
                        ),
                      'CreateSurvey'
                    );
                    return {
                      Component: CreateSurvey,
                    };
                  },
                },
                {
                  path: 'surveys/:surveyId/edit',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { EditSurvey } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EditSurvey" */
                          'views/private/surveys/EditSurvey'
                        ),
                      'EditSurvey'
                    );
                    return {
                      Component: EditSurvey,
                    };
                  },
                },
                {
                  path: 'surveys/:surveyId/preview',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { PreviewSurvey } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "PreviewSurvey" */
                          'views/private/surveys/PreviewSurvey'
                        ),
                      'PreviewSurvey'
                    );
                    return {
                      Component: PreviewSurvey,
                    };
                  },
                },
                {
                  path: 'surveys/:surveyId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Survey } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Survey" */
                          'views/private/surveys/Survey'
                        ),
                      'Survey'
                    );
                    return {
                      element: <Survey />,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      loader: () => {
                        return userTypeGuardLoader(
                          [USER_TYPES.breakline],
                          queryClient
                        );
                      },
                      lazy: async () => {
                        const { SurveyList } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "SurveyList" */
                              'views/private/surveys/SurveyList'
                            ),
                          'SurveyList'
                        );
                        return {
                          Component: SurveyList,
                        };
                      },
                    },
                    {
                      path: 'submissions',
                      errorElement: <NestedRouteErrorHandler />,
                      loader: () => {
                        return userTypeGuardLoader(
                          [USER_TYPES.breakline],
                          queryClient
                        );
                      },
                      lazy: async () => {
                        const { SurveyList } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "SurveyList" */
                              'views/private/surveys/SurveyList'
                            ),
                          'SurveyList'
                        );
                        return {
                          element: <SurveyList type="submissions" />,
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'surveys/submissions/:submissionId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Submission } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Submission" */
                          'views/private/surveys/SurveySubmission'
                        ),
                      'Submission'
                    );
                    return {
                      Component: Submission,
                    };
                  },
                },
                {
                  path: 'interview-reflections',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.participant],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { ParticipantSurveys } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "ParticipantSurveys" */
                          'views/private/surveys/ParticipantSurveys'
                        ),
                      'ParticipantSurveys'
                    );
                    return {
                      Component: ParticipantSurveys,
                    };
                  },
                },
                {
                  path: 'participants',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { Participants } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Participants" */
                          'modules/team/participant/views/Participants'
                        ),
                      'Participants'
                    );
                    return {
                      Component: Participants,
                    };
                  },
                },
                {
                  path: 'participants/:participantId/edit',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { EditParticipant } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EditParticipant" */
                          'views/private/participants/EditParticipant'
                        ),
                      'EditParticipant'
                    );
                    return {
                      Component: EditParticipant,
                    };
                  },
                },
                {
                  path: 'participants/:participantId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: async (args) => {
                    await userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );

                    return participantLoader(queryClient, args);
                  },
                  shouldRevalidate: () => false,
                  lazy: async () => {
                    const { Participant } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Participant" */
                          'views/private/participants/Participant'
                        ),
                      'Participant'
                    );
                    return {
                      Component: Participant,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { ParticipantProfile } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "ParticipantProfile" */
                              'views/private/participants/ParticipantProfile'
                            ),
                          'ParticipantProfile'
                        );
                        return {
                          Component: ParticipantProfile,
                        };
                      },
                    },
                    {
                      path: 'opportunities',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { ParticipantOpportunities } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "ParticipantOpportunities" */
                              'views/private/participants/ParticipantOpportunities'
                            ),
                          'ParticipantOpportunities'
                        );
                        return {
                          Component: ParticipantOpportunities,
                        };
                      },
                    },
                    {
                      path: 'coaching',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { ParticipantCoaching } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "ParticipantCoaching" */
                              'views/private/participants/ParticipantCoaching'
                            ),
                          'ParticipantCoaching'
                        );
                        return {
                          Component: ParticipantCoaching,
                        };
                      },
                    },
                    {
                      path: 'statuses',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { ParticipantStatuses } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "ParticipantStatuses" */
                              'views/private/participants/ParticipantStatuses'
                            ),
                          'ParticipantStatuses'
                        );
                        return {
                          Component: ParticipantStatuses,
                        };
                      },
                    },
                  ],
                },
                // TAGS
                {
                  path: 'tags',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { Tags } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Tags" */
                          'views/private/tags/Tags'
                        ),
                      'Tags'
                    );

                    return {
                      Component: Tags,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { IndexTagsSkills } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "IndexTagsSkills" */
                              'views/private/tags/TagsSkills'
                            ),
                          'IndexTagsSkills'
                        );
                        return {
                          Component: IndexTagsSkills,
                        };
                      },
                    },
                    {
                      path: 'skills',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsSkills } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsSkills" */
                              'views/private/tags/TagsSkills'
                            ),
                          'TagsSkills'
                        );
                        return {
                          Component: TagsSkills,
                        };
                      },
                    },
                    {
                      path: 'tracks',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsTracks } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsTracks" */
                              'views/private/tags/TagsTracks'
                            ),
                          'TagsTracks'
                        );
                        return {
                          Component: TagsTracks,
                        };
                      },
                    },
                    {
                      path: 'locations',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsLocations } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsLocations" */
                              'views/private/tags/TagsLocations'
                            ),
                          'TagsLocations'
                        );
                        return {
                          Component: TagsLocations,
                        };
                      },
                    },
                    {
                      path: 'job-functions',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsJobFunctions } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsJobFunctions" */
                              'views/private/tags/TagsJobFunctions'
                            ),
                          'TagsJobFunctions'
                        );
                        return {
                          Component: TagsJobFunctions,
                        };
                      },
                    },
                    {
                      path: 'content-categories',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsContentCategories } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsContentCategories" */
                              'views/private/tags/TagsContentCategories'
                            ),
                          'TagsContentCategories'
                        );
                        return {
                          Component: TagsContentCategories,
                        };
                      },
                    },
                    {
                      errorElement: <NestedRouteErrorHandler />,
                      path: 'event-categories',
                      lazy: async () => {
                        const { TagsEventCategories } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsEventCategories" */
                              'views/private/tags/TagsEventCategories'
                            ),
                          'TagsEventCategories'
                        );
                        return {
                          Component: TagsEventCategories,
                        };
                      },
                    },
                    {
                      path: 'self-identifications',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsSelfIdentifications } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsSelfIdentifications" */
                              'views/private/tags/TagsSelfIdentifications'
                            ),
                          'TagsSelfIdentifications'
                        );
                        return {
                          Component: TagsSelfIdentifications,
                        };
                      },
                    },
                    {
                      path: 'segments',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsSegments } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsSegments" */
                              'views/private/tags/TagsSegments'
                            ),
                          'TagsSegments'
                        );
                        return {
                          Component: TagsSegments,
                        };
                      },
                    },
                    {
                      path: 'partner-types',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsPartnerTypes } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsPartnerTypes" */
                              'views/private/tags/TagsPartnerTypes'
                            ),
                          'TagsPartnerTypes'
                        );
                        return {
                          Component: TagsPartnerTypes,
                        };
                      },
                    },
                    {
                      path: 'partner-stages',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsPartnerStages } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsPartnerStages" */
                              'views/private/tags/TagsPartnerStages'
                            ),
                          'TagsPartnerStages'
                        );
                        return {
                          Component: TagsPartnerStages,
                        };
                      },
                    },
                    {
                      path: 'partner-spaces',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsPartnerSpaces } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsPartnerSpaces" */
                              'views/private/tags/TagsPartnerSpaces'
                            ),
                          'TagsPartnerSpaces'
                        );
                        return {
                          Component: TagsPartnerSpaces,
                        };
                      },
                    },
                    {
                      path: 'recruiter-types',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsRecruiterTypes } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsRecruiterTypes" */
                              'views/private/tags/TagsRecruiterTypes'
                            ),
                          'TagsRecruiterTypes'
                        );
                        return {
                          Component: TagsRecruiterTypes,
                        };
                      },
                    },
                    {
                      path: 'recruiter-statuses',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { TagsRecruiterStatuses } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "TagsRecruiterStatuses" */
                              'views/private/tags/TagsRecruiterStatuses'
                            ),
                          'TagsRecruiterStatuses'
                        );
                        return {
                          Component: TagsRecruiterStatuses,
                        };
                      },
                    },
                  ],
                },
                // TEAM
                {
                  path: 'team',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return Promise.all([
                      userTypeGuardLoader([USER_TYPES.breakline], queryClient),
                      userAccessGuardLoader([USER_ACCESS.admin], queryClient),
                    ]);
                  },
                  lazy: async () => {
                    const { Team } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Team" */
                          'views/private/Team'
                        ),
                      'Team'
                    );

                    return {
                      Component: Team,
                    };
                  },
                },
                // PARTNERS/COMPANIES
                {
                  path: 'companies',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Companies } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Companies" */
                          'modules/participant/companies/views/Companies'
                        ),
                      'Companies'
                    );
                    return {
                      loader: async () => {
                        const user = await queryClient.ensureQueryData(
                          USER_OPTIONS.user()
                        );
                        if (user.type === 'participant') {
                          return null;
                        }

                        if (user.type === 'breakline') {
                          return redirect('/partners');
                        }

                        return notFound();
                      },
                      Component: Companies,
                    };
                  },
                },
                {
                  path: 'companies/:partnerId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Company, companyLoader } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Company" */
                          'modules/participant/companies/views/Company'
                        ),
                      'Company'
                    );
                    return {
                      loader: async (args) => {
                        const user = await queryClient.ensureQueryData(
                          USER_OPTIONS.user()
                        );
                        const { params } = args;
                        const { partnerId } = params;

                        if (!partnerId) {
                          return notFound();
                        }

                        if (user.type === 'participant') {
                          return companyLoader(queryClient, partnerId);
                        }

                        if (user.type === 'breakline') {
                          return redirect(`/partners/${partnerId}`);
                        }

                        return notFound();
                      },
                      Component: Company,
                    };
                  },
                },
                {
                  path: 'partners',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: async () => {
                    const user = await queryClient.ensureQueryData(
                      USER_OPTIONS.user()
                    );

                    if (user.type === 'breakline') {
                      return null;
                    }

                    if (user.type === 'participant') {
                      return redirect('/companies');
                    }

                    return notFound();
                  },
                  lazy: async () => {
                    const { Partners } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Partners" */
                          'views/private/partners/Partners'
                        ),
                      'Partners'
                    );
                    return {
                      Component: Partners,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { BreaklinePartners } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "BreaklinePartners" */
                              'views/private/partners/BreaklinePartnersView'
                            ),
                          'BreaklinePartners'
                        );
                        return {
                          element: <BreaklinePartners />,
                        };
                      },
                    },
                    {
                      path: 'all',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { BreaklinePartners } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "BreaklinePartners" */
                              'views/private/partners/BreaklinePartnersView'
                            ),
                          'BreaklinePartners'
                        );
                        return {
                          element: <BreaklinePartners allPartners />,
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'partners/create',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { CreatePartner } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "CreatePartner" */
                          'views/private/partners/EditPartner'
                        ),
                      'CreatePartner'
                    );
                    return {
                      Component: CreatePartner,
                    };
                  },
                },
                {
                  path: 'partners/:partnerId/edit',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { EditPartner } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EditPartner" */
                          'views/private/partners/EditPartner'
                        ),
                      'EditPartner'
                    );
                    return {
                      Component: EditPartner,
                    };
                  },
                },
                {
                  path: 'partners/:partnerId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { Partner } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Partner" */
                          'views/private/partners/Partner'
                        ),
                      'Partner'
                    );
                    return {
                      loader: async (args) => {
                        const user = await queryClient.ensureQueryData(
                          USER_OPTIONS.user()
                        );
                        const { params } = args;
                        const { partnerId } = params;

                        if (!partnerId) {
                          return notFound();
                        }

                        if (user.type === 'breakline') {
                          return null;
                        }

                        if (user.type === 'participant') {
                          return redirect(`/companies/${partnerId}`);
                        }

                        return notFound();
                      },
                      Component: Partner,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { BreaklinePartnerRolesList } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "BreaklinePartnerRolesList" */
                              'views/private/partners/BreaklinePartnerRolesList'
                            ),
                          'BreaklinePartnerRolesList'
                        );
                        return {
                          Component: BreaklinePartnerRolesList,
                        };
                      },
                    },
                    {
                      path: 'contact-list',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { BreaklinePartnerContactsList } =
                          await lazyRetry(
                            () =>
                              import(
                                /* webpackChunkName: "BreaklinePartnerContactsList" */
                                'views/private/partners/BreaklinePartnerContactsList'
                              ),
                            'BreaklinePartnerContactsList'
                          );
                        return {
                          Component: BreaklinePartnerContactsList,
                        };
                      },
                    },
                    {
                      path: 'hired',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { BreaklinePartnerHiredList } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "BreaklinePartnerHiredList" */
                              'views/private/partners/BreaklinePartnerHiredList'
                            ),
                          'BreaklinePartnerHiredList'
                        );
                        return {
                          Component: BreaklinePartnerHiredList,
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'partners/:partnerId/preview',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { PreviewPartner } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "PreviewPartner" */
                          'views/private/partners/PreviewPartner'
                        ),
                      'PreviewPartner'
                    );
                    return {
                      loader: () => {
                        return userTypeGuardLoader(
                          [USER_TYPES.breakline],
                          queryClient
                        );
                      },
                      Component: PreviewPartner,
                    };
                  },
                },
                // PARTNER CONTACTS
                {
                  path: 'partners/:partnerId/contact-list/:contactId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { PartnerContact } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "PartnerContact" */
                          'views/private/partners/PartnerContact'
                        ),
                      'PartnerContact'
                    );
                    return {
                      Component: PartnerContact,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { PartnerContactProfile } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "PartnerContactProfile" */
                              'views/private/partners/PartnerContactProfile'
                            ),
                          'PartnerContactProfile'
                        );
                        return {
                          Component: PartnerContactProfile,
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'partners/:partnerId/contact-list/create',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { CreatePartnerContact } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "CreatePartnerContact" */
                          'views/private/partners/CreatePartnerContact'
                        ),
                      'CreatePartnerContact'
                    );
                    return {
                      Component: CreatePartnerContact,
                    };
                  },
                },
                {
                  path: 'partners/:partnerId/contact-list/:contactId/edit',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { EditPartnerContact } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EditPartnerContact" */
                          'views/private/partners/CreatePartnerContact'
                        ),
                      'EditPartnerContact'
                    );
                    return {
                      Component: EditPartnerContact,
                    };
                  },
                },
                // GAME PLANS
                {
                  path: 'jobs/game-plan/:gamePlanId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { JobGamePlans } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "JobGamePlans" */
                          'views/private/jobs/JobGamePlans'
                        ),
                      'JobGamePlans'
                    );
                    return {
                      loader: async () => {
                        const isUtahParticipant = await getIsUtahParticipant(
                          queryClient,
                          posthogClient
                        );

                        if (isUtahParticipant) {
                          notFound();
                        }

                        return null;
                      },
                      Component: JobGamePlans,
                    };
                  },
                },
                {
                  path: 'game-plans',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: async () => {
                    await userTypeGuardLoader(
                      [USER_TYPES.participant],
                      queryClient
                    );
                    const isUtahParticipant = await getIsUtahParticipant(
                      queryClient,
                      posthogClient
                    );

                    if (isUtahParticipant) {
                      notFound();
                    }

                    return null;
                  },
                  lazy: async () => {
                    const { GamePlans } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "GamePlans" */
                          'views/private/GamePlans'
                        ),
                      'GamePlans'
                    );
                    return {
                      Component: GamePlans,
                    };
                  },
                },
                // CONTENT LIBRARY
                {
                  path: 'content-library',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { ContentLibrary } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "ContentLibrary" */
                          'views/private/content-library/ContentLibrary'
                        ),
                      'ContentLibrary'
                    );
                    return {
                      Component: ContentLibrary,
                    };
                  },
                },
                {
                  path: 'content-library/:contentId',
                  errorElement: <TopLevelRouteErrorHandler />,
                  lazy: async () => {
                    const { ContentLibraryItem } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "ContentLibraryItem" */
                          'views/private/content-library/ContentLibraryItem'
                        ),
                      'ContentLibraryItem'
                    );
                    return {
                      Component: ContentLibraryItem,
                    };
                  },
                },
                {
                  path: 'content-library/:contentId/edit',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { EditContentLibraryItem } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "EditContentLibraryItem" */
                          'views/private/content-library/EditContentLibraryItem'
                        ),
                      'EditContentLibraryItem'
                    );
                    return {
                      Component: EditContentLibraryItem,
                    };
                  },
                },
                {
                  path: 'content-library/create',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { CreateContentLibraryItem } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "CreateContentLibraryItem" */
                          'views/private/content-library/EditContentLibraryItem'
                        ),
                      'CreateContentLibraryItem'
                    );
                    return {
                      Component: CreateContentLibraryItem,
                    };
                  },
                },
                {
                  path: 'hired',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { Hired } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Hired" */
                          'modules/team/hire/views/Hired'
                        ),
                      'Hired'
                    );
                    return {
                      Component: Hired,
                    };
                  },
                },
                {
                  path: 'shortlist',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { ShortList } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "ShortList" */
                          'views/private/ShortList'
                        ),
                      'ShortList'
                    );
                    return {
                      Component: ShortList,
                    };
                  },
                },
                {
                  path: 'automation',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { Automation } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Automation" */
                          'views/private/automation/Automation'
                        ),
                      'Automation'
                    );
                    return {
                      Component: Automation,
                    };
                  },
                  children: [
                    {
                      index: true,
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { AutomationStage } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AutomationStage" */
                              'views/private/automation/AutomationStage'
                            ),
                          'AutomationStage'
                        );
                        return {
                          Component: AutomationStage,
                        };
                      },
                    },
                    {
                      path: 'stage/resource',
                      loader: () => {
                        return redirect('/automation');
                      },
                    },
                    {
                      path: 'partner/resource',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { AutomationPartner } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AutomationPartner" */
                              'views/private/automation/AutomationPartner'
                            ),
                          'AutomationPartner'
                        );
                        return {
                          Component: AutomationPartner,
                        };
                      },
                    },
                    {
                      path: 'function/resource',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { AutomationFunction } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AutomationFunction" */
                              'views/private/automation/AutomationFunction'
                            ),
                          'AutomationFunction'
                        );
                        return {
                          Component: AutomationFunction,
                        };
                      },
                    },
                    {
                      path: 'module/resource',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { AutomationModule } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AutomationModule" */
                              'views/private/automation/AutomationModule'
                            ),
                          'AutomationModule'
                        );
                        return {
                          Component: AutomationModule,
                        };
                      },
                    },
                    {
                      path: 'stage/survey',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { AutomationStageSurvey } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AutomationStageSurvey" */
                              'views/private/automation/AutomationStageSurvey'
                            ),
                          'AutomationStageSurvey'
                        );
                        return {
                          Component: AutomationStageSurvey,
                        };
                      },
                    },
                    {
                      path: 'event/survey',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { AutomationEventSurvey } = await lazyRetry(
                          () =>
                            import(
                              /* webpackChunkName: "AutomationEventSurvey" */
                              'views/private/automation/AutomationEventSurvey'
                            ),
                          'AutomationEventSurvey'
                        );
                        return {
                          Component: AutomationEventSurvey,
                        };
                      },
                    },
                    {
                      path: 'platform-event/survey',
                      errorElement: <NestedRouteErrorHandler />,
                      lazy: async () => {
                        const { AutomationPlatformEventSurvey } =
                          await lazyRetry(
                            () =>
                              import(
                                /* webpackChunkName: "AutomationPlatformEventSurvey" */
                                'views/private/automation/AutomationPlatformEventSurvey'
                              ),
                            'AutomationPlatformEventSurvey'
                          );
                        return {
                          Component: AutomationPlatformEventSurvey,
                        };
                      },
                    },
                  ],
                },
                {
                  path: 'matches',
                  errorElement: <TopLevelRouteErrorHandler />,
                  loader: () => {
                    return userTypeGuardLoader(
                      [USER_TYPES.breakline],
                      queryClient
                    );
                  },
                  lazy: async () => {
                    const { Recommendations } = await lazyRetry(
                      () =>
                        import(
                          /* webpackChunkName: "Recommendations" */
                          'views/private/Recommendations'
                        ),
                      'Recommendations'
                    );
                    return {
                      Component: Recommendations,
                    };
                  },
                },
                {
                  path: '*',
                  element: (
                    <div className="content-container flex min-h-[calc(100dvh-var(--siteheader-height))] items-center justify-center">
                      <NotFound />
                    </div>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'onboarding',
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { Onboarding, onboardingLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "Onboarding" */
                  'views/private/Onboarding'
                ),
              'Onboarding'
            );

            return {
              loader: () => onboardingLoader(queryClient),
              Component: Onboarding,
            };
          },
        },
        {
          path: UTAH_PATHS.onboarding,
          errorElement: <TopLevelRouteErrorHandler />,
          lazy: async () => {
            const { UtahOnboarding, utahOnboardingLoader } = await lazyRetry(
              () =>
                import(
                  /* webpackChunkName: "UtahOnboarding" */
                  'modules/participant/utah/views/UtahOnboarding'
                ),
              'UtahOnboarding'
            );
            return {
              shouldRevalidate: () => false,
              loader: async () => {
                return utahOnboardingLoader(queryClient);
              },
              Component: UtahOnboarding,
            };
          },
          children: [
            {
              index: true,
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { UtahOnboardingWelcome, utahOnboardingWelcomeLoader } =
                  await lazyRetry(
                    () =>
                      import(
                        /* webpackChunkName: "UtahOnboardingWelcome" */
                        'modules/participant/utah/views/UtahOnboardingWelcome'
                      ),
                    'UtahOnboardingWelcome'
                  );
                return {
                  loader: (args) => {
                    return maybeRedirectToLatestStep(
                      args.request.url,
                      queryClient,
                      () => {
                        return maybeAccessStep(
                          UTAH_PROGRAM_AVAILABLE_STEPS.welcome.step,
                          queryClient,
                          () => {
                            return utahOnboardingWelcomeLoader();
                          }
                        );
                      }
                    );
                  },
                  Component: UtahOnboardingWelcome,
                };
              },
            },
            {
              path: 'basic-info',
              errorElement: <NestedRouteErrorHandler />,
              lazy: async () => {
                const { UtahOnboardingBasicInfo } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "UtahOnboardingBasicInfo" */
                      'modules/participant/utah/views/UtahOnboardingBasicInfo'
                    ),
                  'UtahOnboardingBasicInfo'
                );
                return {
                  loader: (args) => {
                    return maybeRedirectToLatestStep(
                      args.request.url,
                      queryClient,
                      () => {
                        return maybeAccessStep(
                          UTAH_PROGRAM_AVAILABLE_STEPS['basic-info'].step,
                          queryClient,
                          () => {
                            return utahOnboardingBasicInfoLoader();
                          }
                        );
                      }
                    );
                  },
                  Component: UtahOnboardingBasicInfo,
                };
              },
            },
            {
              path: 'self-identification',
              lazy: async () => {
                const {
                  UtahOnboardingSelfIdentification,
                  utahOnboardingSelfIdentificationLoader,
                } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "UtahOnboardingSelfIdentification" */
                      'modules/participant/utah/views/UtahOnboardingSelfIdentification'
                    ),
                  'UtahOnboardingSelfIdentification'
                );
                return {
                  loader: (args) => {
                    return maybeRedirectToLatestStep(
                      args.request.url,
                      queryClient,
                      () => {
                        return maybeAccessStep(
                          UTAH_PROGRAM_AVAILABLE_STEPS.demographics.step,
                          queryClient,
                          () => {
                            return utahOnboardingSelfIdentificationLoader(
                              queryClient
                            );
                          }
                        );
                      }
                    );
                  },
                  element: <UtahOnboardingSelfIdentification />,
                };
              },
            },
            {
              path: 'taxonomy',
              lazy: async () => {
                const { UtahOnboardingTaxonomy, utahOnboardingTaxonomyLoader } =
                  await lazyRetry(
                    () =>
                      import(
                        /* webpackChunkName: "UtahOnboardingTaxonomy" */
                        'modules/participant/utah/views/UtahOnboardingTaxonomy'
                      ),
                    'UtahOnboardingTaxonomy'
                  );
                return {
                  loader: (args) => {
                    return maybeRedirectToLatestStep(
                      args.request.url,
                      queryClient,
                      () => {
                        return maybeAccessStep(
                          UTAH_PROGRAM_AVAILABLE_STEPS.taxonomy.step,
                          queryClient,
                          () => {
                            return utahOnboardingTaxonomyLoader(queryClient);
                          }
                        );
                      }
                    );
                  },
                  element: <UtahOnboardingTaxonomy />,
                };
              },
            },
            {
              path: 'bonus',
              lazy: async () => {
                const { UtahOnboardingBonus, utahOnboardingBonusLoader } =
                  await lazyRetry(
                    () =>
                      import(
                        /* webpackChunkName: "UtahOnboardingBonus" */
                        'modules/participant/utah/views/UtahOnboardingBonus'
                      ),
                    'UtahOnboardingBonus'
                  );
                return {
                  loader: (args) => {
                    return maybeRedirectToLatestStep(
                      args.request.url,
                      queryClient,
                      () => {
                        return maybeAccessStep(
                          UTAH_PROGRAM_AVAILABLE_STEPS.bonus.step,
                          queryClient,
                          () => {
                            return utahOnboardingBonusLoader();
                          }
                        );
                      }
                    );
                  },
                  element: <UtahOnboardingBonus />,
                };
              },
            },
            {
              path: 'commitment',
              lazy: async () => {
                const {
                  UtahOnboardingCommitment,
                  utahOnboardingCommitmentLoader,
                } = await lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "UtahOnboardingCommitment" */
                      'modules/participant/utah/views/UtahOnboardingCommitment'
                    ),
                  'UtahOnboardingCommitment'
                );
                return {
                  loader: (args) => {
                    return maybeRedirectToLatestStep(
                      args.request.url,
                      queryClient,
                      () => {
                        return maybeAccessStep(
                          UTAH_PROGRAM_AVAILABLE_STEPS.commitment.step,
                          queryClient,
                          () => {
                            return utahOnboardingCommitmentLoader();
                          }
                        );
                      }
                    );
                  },
                  element: <UtahOnboardingCommitment />,
                };
              },
            },
            {
              path: '*',
              element: (
                <div className="content-container flex min-h-[calc(100dvh-var(--siteheader-height))] items-center justify-center">
                  <NotFound />
                </div>
              ),
            },
          ],
        },
      ],
    },
  ];
}
