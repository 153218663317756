import * as Sentry from '@sentry/react';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  USER_ACCESS,
  USER_ADMIT_DECISIONS,
  USER_TYPES,
} from 'api/user/user.constants';
import { Auth } from 'aws-amplify';

import api from 'dataService/api';
import { posthogClient } from 'modules/common/posthog/api/posthog-client';

export const USER_OPTIONS = {
  user: () => {
    return queryOptions({
      queryKey: ['user'] as const,
      queryFn: async ({ signal }) => {
        try {
          const response = await api.get<UserProfileResponse>('/auth/profile', {
            signal,
          });

          Sentry.setUser({
            email: response.data.email,
            username: response.data.username,
          });

          Sentry.setTag('user_type', response.data.type);

          posthogClient?.identify(response.data.id.toString(), {
            email: response.data.email,
            username: response.data.username,
            user_type: response.data.type,
            programs:
              response.data.profile?.programs?.map((program) => program.id) ??
              [],
          });
          posthogClient?.setPersonPropertiesForFlags({
            id: response.data.id.toString(),
            email: response.data.email,
            username: response.data.username,
            user_type: response.data.type,
            programs:
              response.data.profile?.programs?.map((program) => program.id) ??
              [],
          });

          return response.data;
        } catch (e) {
          /**
           * Nuke cognito auth state if auth profile endpoint is not working as
           * expected even though cognito auth is valid.
           */
          await Auth.signOut();
          throw e;
        }
      },
      retry: 3,
      staleTime: 60 * 1000 * 10, // 10min
    });
  },
  currentUser: () => {
    return queryOptions({
      queryKey: ['current-user'] as const,
      queryFn: async () => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          return user;
        } catch (error) {
          throw error;
        }
      },
    });
  },
  demographics: (id: number) => {
    return queryOptions({
      queryKey: [...USER_OPTIONS.user().queryKey, 'demographics', id] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get(`/users/${id}/demographics`, {
          signal,
        });

        return response.data;
      },
    });
  },
};

// TODO: This should be moved to a shared location and more robustly typed
export interface UserProfileResponse {
  type: keyof typeof USER_TYPES;
  admitDecision: keyof typeof USER_ADMIT_DECISIONS;
  codeOfConduct: boolean;
  onboardingStep: number;
  id: number;
  headshot?: {
    location?: string;
  };
  active: boolean;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  status: 'Active' | 'Pending' | 'None';
  statuses?: { status: { id: number; type: string } }[];
  roles: (keyof typeof USER_ACCESS)[];
  segments: { name: string }[];
  featureAnnouncements: { id: number; featureId: number }[];
  exo?: Partial<Omit<UserProfileResponse, 'profile'>>;
  profile?: {
    originalResume?: string;
    oneLiner?: string;
    finalResume?: string;
    totalCompensation?: string;
    linkedin?: string;
    locations?: { id: number; name: string }[];
    skills?: { id: number; name: string }[];
    interviewer?: Partial<Omit<UserProfileResponse, 'profile'>>;
    dateAvailable: string;
    programs?: Array<{ id: number; name: string }>;
    workEnvironment?: Array<string>;
    employmentType?: Array<string>;
    compensationExpectation?: number;
    jobFunctions?: Array<{ name: string; id: number }>;
    industries?: Array<{ name: string; id: number }>;
  };
  partner?: {
    name: string;
  };
  ldHash: string;
  previouslyActivated: boolean;
}

export function useUserProfile() {
  return useQuery({
    ...USER_OPTIONS.user(),
    throwOnError: false,
    retry: false,
  });
}

export function useLogout() {
  const queryClient = useQueryClient();

  async function logout() {
    await Auth.signOut();
  }

  return useMutation({
    mutationFn: logout,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: USER_OPTIONS.user().queryKey });
    },
  });
}
