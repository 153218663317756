import { useSuspenseQuery } from '@tanstack/react-query';
import { useLogout, USER_OPTIONS } from 'api/user/user';
import { CurrentUserAvatar } from 'components/CurrentUserAvatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ds/DropdownMenu';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { Logo } from 'components/ds/Logo';
import { LoadingContainer } from 'components/ds/Spinner';
import { toast } from 'components/ds/Toast/Toast';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { focusRingStyles } from 'styles/focus';
import { twMerge } from 'tailwind-merge';

export function SplitLayout({ children }: { children: React.ReactNode }) {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const fullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  const logoutMutation = useLogout();
  const navigate = useNavigate();

  return (
    <SplitLayoutPortalProvider>
      <div className="[--site-header-height:58px] lg:[--site-header-height:71px]">
        <header className="fixed left-0 top-0 z-10 flex h-[var(--site-header-height)] w-full items-center justify-between gap-5 bg-ds-bg-foundation px-5 lg:w-1/2 lg:px-6 2xl:w-2/3">
          <Logo className="w-full max-w-[150px]" aria-hidden theme="dark" />
          <DropdownMenu>
            <DropdownMenuTrigger
              aria-label={fullName}
              className={focusRingStyles({
                className:
                  'items-center overflow-clip rounded-md border border-transparent px-2 py-1 transition-all stack-x-3 hover:border-ds-stroke-tertiary hover:bg-ds-bg-weaker',
              })}
            >
              <span className="items-center stack-x-2">
                <CurrentUserAvatar />
                <span className="text-xs text-ds-text-primary">
                  {user?.firstName}
                </span>
              </span>
              <span className="block" aria-hidden>
                <Icon className="h-4 w-4 text-ds-icon-primary" aria-hidden>
                  <IconUse id="arrow-down-s-line" />
                </Icon>
              </span>
            </DropdownMenuTrigger>

            <DropdownMenuContent align="end">
              <DropdownMenuItem className="w-full text-left stack-x-2" asChild>
                <button
                  onClick={() => {
                    logoutMutation.mutate(undefined, {
                      onSuccess: () => {
                        navigate('/login', { replace: true });
                      },
                      onError: () => {
                        toast.error('Logout was unsuccessful');
                      },
                    });
                  }}
                >
                  <Icon aria-hidden className="h-4 w-4 text-ds-icon-primary">
                    <IconUse id="logout-box-line" />
                  </Icon>
                  Logout
                </button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </header>
        <main className="relative z-0 min-h-[100dvh] w-full">
          <div className="flex h-full w-full">
            <div className="flex w-full pt-[var(--site-header-height)] lg:w-1/2 2xl:w-2/3">
              <div className="flex h-full max-h-[calc(100dvh-var(--site-header-height))] min-h-[max(calc(100dvh-var(--site-header-height)),calc(500px+var(--site-header-height)))] w-full items-center">
                <SplitLayoutContentWrapper>
                  <React.Suspense
                    fallback={
                      <LoadingContainer className="w-full" level="component" />
                    }
                  >
                    {children}
                  </React.Suspense>
                </SplitLayoutContentWrapper>
              </div>
            </div>
            <div className="hidden w-1/2 lg:flex 2xl:w-1/3">
              <div className="w-full py-3 pr-3">
                <div className=" relative flex h-full w-full items-center justify-center overflow-hidden rounded-lg">
                  <img
                    aria-hidden
                    alt=""
                    src="/images/utah-onboarding-painting.webp"
                    rel="preload"
                    className="absolute inset-0 z-0 h-full w-full object-cover"
                  />
                  <div className="relative z-10 flex h-full w-full flex-col gap-5">
                    <SplitLayoutRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </SplitLayoutPortalProvider>
  );
}
function SplitLayoutContentWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const { containerWrapper } = React.useContext(SplitLayoutContext) ?? {};
  const [, setSplitLayoutContainerWrapper] = containerWrapper ?? [];

  return (
    <div
      ref={setSplitLayoutContainerWrapper}
      className="flex h-full w-full flex-col overflow-y-auto overflow-x-clip"
    >
      <div className="h-full w-full pt-5">{children}</div>
    </div>
  );
}

export function useScrollSplitToTop() {
  const { containerWrapper } = React.useContext(SplitLayoutContext) ?? {};
  const [elem] = containerWrapper ?? [];
  const scrollTo = React.useCallback(() => {
    if (elem) {
      elem.scrollTo(0, 0);
    }
  }, [elem]);
  React.useEffect(() => {
    scrollTo();
  }, [scrollTo]);
}

export function SplitLayoutLeft({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={twMerge('flex h-full flex-col', className)}>{children}</div>
  );
}

export function SplitLayoutLeftContent({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        'flex flex-1 flex-col gap-6 pb-[calc(theme(spacing.10)+theme(spacing.20))]',
        className
      )}
    >
      {children}
    </div>
  );
}

export function SplitLayoutLeftFooter({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        'fixed bottom-0 left-0 flex w-full flex-wrap items-center justify-between gap-5 bg-ds-bg-foundation px-5 pb-5 pt-5 md:px-10 md:pb-10 lg:w-1/2 2xl:w-2/3',
        className
      )}
    >
      {children}
    </div>
  );
}

function SplitLayoutRight() {
  const { portal } = React.useContext(SplitLayoutContext) ?? {};
  const [, setSplitLayoutPortal] = portal ?? [];

  return (
    <div className="h-full w-full empty:hidden" ref={setSplitLayoutPortal} />
  );
}

const SplitLayoutContext = React.createContext<{
  portal: [
    HTMLDivElement | null,
    React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
  ];
  containerWrapper: [
    HTMLDivElement | null,
    React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
  ];
} | null>(null);

export function SplitLayoutPortalProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const splitLayoutPortalState = React.useState<HTMLDivElement | null>(null);
  const splitLayoutContainerWrapperState =
    React.useState<HTMLDivElement | null>(null);

  const value = React.useMemo(() => {
    return {
      portal: splitLayoutPortalState,
      containerWrapper: splitLayoutContainerWrapperState,
    };
  }, [splitLayoutPortalState, splitLayoutContainerWrapperState]);

  return (
    <SplitLayoutContext.Provider value={value}>
      {children}
    </SplitLayoutContext.Provider>
  );
}

export function SplitLayoutPortal({ children }: { children: React.ReactNode }) {
  const { portal } = React.useContext(SplitLayoutContext) ?? {};
  const [portalContentElement] = portal ?? [];

  return portalContentElement
    ? ReactDOM.createPortal(children, portalContentElement)
    : null;
}
