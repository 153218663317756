import { Icon } from 'components/ds/icons/Icon';
import { IconLoader } from 'components/icons/IconLoader/IconLoader';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { focusRingStyles } from 'styles/focus';
import { tv, VariantProps } from 'tailwind-variants';

interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'prefix'>,
    VariantProps<typeof buttonStyles> {
  asChild?: boolean;
  prefix?: React.ReactElement;
  suffix?: React.ReactElement;
}
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      prefix,
      suffix,
      variant,
      size,
      isLoading,
      svgOnly,
      align,
      asChild = false,
      children,
      ...rest
    },
    ref
  ) => {
    const { button, content, icon } = buttonStyles({
      variant,
      size,
      svgOnly,
      align,
      isLoading,
    });
    return (
      <ButtonContext.Provider value={{ size, svgOnly, variant }}>
        <button className={button({ className })} ref={ref} {...rest}>
          {isLoading && prefix ? (
            <>
              <IconLoader animate className={icon()} />
            </>
          ) : (
            prefix
          )}
          {isLoading && !(prefix || suffix) && (
            <IconLoader animate className={icon()} />
          )}
          {children && <span className={content()}>{children}</span>}
          {isLoading && suffix ? (
            <>
              <IconLoader animate className={icon()} />
            </>
          ) : (
            suffix
          )}
        </button>
      </ButtonContext.Provider>
    );
  }
);
Button.displayName = 'Button';

interface ButtonLinkProps
  extends Omit<React.ComponentProps<typeof Link>, 'prefix'>,
    VariantProps<typeof buttonStyles> {
  asChild?: boolean;
  prefix?: React.ReactElement;
  suffix?: React.ReactElement;
}
export const ButtonLink = React.forwardRef<
  React.ElementRef<typeof Link>,
  ButtonLinkProps
>(
  (
    {
      className,
      prefix,
      suffix,
      variant,
      size,
      isLoading,
      svgOnly,
      align,
      asChild = false,
      children,
      ...rest
    },
    ref
  ) => {
    const { button, content, icon } = buttonStyles({
      variant,
      size,
      svgOnly,
      align,
      isLoading,
    });
    return (
      <ButtonContext.Provider value={{ size, svgOnly, variant }}>
        <Link className={button({ className })} ref={ref} {...rest}>
          {isLoading && prefix ? (
            <>
              <IconLoader animate className={icon()} />
            </>
          ) : (
            prefix
          )}
          {isLoading && !(prefix || suffix) && (
            <IconLoader animate className={icon()} />
          )}
          <span className={content()}>{children}</span>
          {isLoading && suffix ? (
            <>
              <IconLoader animate className={icon()} />
            </>
          ) : (
            suffix
          )}
        </Link>
      </ButtonContext.Provider>
    );
  }
);
ButtonLink.displayName = 'ButtonLink';

export const ButtonIcon = React.forwardRef<
  React.ElementRef<typeof Icon>,
  React.ComponentPropsWithoutRef<typeof Icon> &
    VariantProps<typeof buttonStyles>
>(({ className, size, ...props }, ref) => {
  const context = React.useContext(ButtonContext);
  const { icon } = buttonStyles({
    size: context.size ?? size,
  });

  return <Icon ref={ref} className={icon({ className })} {...props} />;
});
ButtonIcon.displayName = 'ButtonIcon';

export const buttonStyles = tv(
  {
    slots: {
      button: focusRingStyles({
        className:
          'group/button no-underline text-ds-text-primary inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors disabled:pointer-events-none disabled:opacity-50',
      }),
      icon: 'grid place-items-center text-current',
      content: 'inline-block truncate px-1.5',
    },
    variants: {
      isLoading: {
        true: {
          button: 'opacity-50 !cursor-wait',
        },
      },
      svgOnly: {
        true: {
          button: 'grid place-items-center',
        },
      },
      align: {
        start: {
          content: 'mr-auto',
        },
        end: {
          content: 'ml-auto',
        },
      },
      variant: {
        default: {
          button:
            'bg-ds-btn-primary text-white shadow hover:bg-ds-btn-primary-hover active:bg-ds-btn-primary-active focus-visible:bg-ds-btn-primary-hover hover:text-white',
        },
        secondary: {
          button:
            'bg-ds-btn-secondary text-white shadow hover:bg-ds-btn-secondary-hover active:bg-ds-btn-secondary-active focus-visible:bg-ds-btn-secondary-hover hover:text-white',
        },
        tertiary: {
          button:
            'bg-ds-btn-tertiary shadow hover:bg-ds-btn-tertiary-hover active:bg-ds-btn-tertiary-active focus-visible:bg-ds-btn-tertiary-hover text-ds-text-primary hover:text-ds-text-primary',
        },
        destructive: {
          button:
            'bg-ds-btn-destructive text-white shadow-sm hover:bg-ds-btn-destructive-hover active:bg-ds-btn-destructive-active focus-visible:bg-ds-btn-destructive-hover hover:text-white',
        },
        outline: {
          button:
            'border border-ds-stroke-tertiary bg-ds-bg-foundation shadow-sm hover:bg-ds-bg-weaker hover:border-ds-stroke-secondary active:bg-ds-bg-soft text-ds-text-primary hover:text-ds-text-primary',
        },
        ghost: {
          button:
            'hover:bg-ds-bg-weaker active:bg-ds-bg-soft text-ds-text-primary hover:text-ds-text-primary',
        },
        'ghost-outline': {
          button:
            'border border-transparent hover:bg-ds-bg-weaker hover:border-ds-stroke-tertiary active:bg-ds-bg-soft text-ds-text-primary hover:text-ds-text-primary',
        },
        link: {
          button:
            'text-ds-link-primary hover:text-ds-link-primary-hover underline-offset-4 hover:underline',
        },
      },
      size: {
        sm: {
          button: 'h-8 px-1.5 text-xs',
          icon: 'w-4 h-4',
        },
        default: {
          button: 'h-9 px-2.5 py-2 text-sm',
          icon: 'w-4 h-4',
        },
        lg: {
          button: 'h-10 px-[0.875rem] text-sm',
          icon: 'w-4 h-4',
        },
        icon: {
          button: 'h-9 w-9', // Deprecate in favor of svgOnly
        },
      },
    },
    compoundVariants: [
      {
        svgOnly: true,
        size: 'sm',
        className: {
          button: 'h-8 w-8 p-0',
        },
      },
      {
        svgOnly: true,
        size: 'default',
        className: {
          button: 'h-9 w-9 p-0',
        },
      },
      {
        svgOnly: true,
        size: 'lg',
        className: {
          button: 'h-10 w-10 p-0',
        },
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
      svgOnly: false,
    },
  },
  {
    responsiveVariants: ['md'],
  }
);

const ButtonContext = React.createContext<VariantProps<typeof buttonStyles>>({
  size: 'default',
  svgOnly: false,
  variant: 'default',
});
