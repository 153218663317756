import { Icon, IconUse } from 'components/ds/icons/Icon';
import * as React from 'react';
import { heading } from 'styles/heading';
import { text } from 'styles/text';
import { twMerge } from 'tailwind-merge';

interface EmptyStateProps extends React.ComponentPropsWithoutRef<'div'> {
  border?: boolean;
  visual?: React.ReactNode;
  title?: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
}
export function EmptyState({
  border = false,
  visual,
  title,
  description,
  children,
  className,
  ...rest
}: EmptyStateProps) {
  return (
    <div
      className={twMerge(
        'flex flex-col items-center gap-6 rounded-md px-8 py-12',
        border ? 'border-ds-border border' : 'border-none',
        className
      )}
      {...rest}
    >
      <div className="mx-auto flex max-w-prose flex-col items-center gap-2 text-center">
        {/* Visual */}
        {visual != null && visual}

        {/* Content */}
        {title != null && (
          <span className={heading({ className: 'text-lg' })}>{title}</span>
        )}
        {description != null && typeof description === 'string' && (
          <span
            className={text({
              variant: '14',
              color: 'secondary',
              className: 'text-balance',
            })}
          >
            {description}
          </span>
        )}
        {description != null && typeof description !== 'string' && description}
      </div>

      {/* Actions */}
      {children != null && (
        <div className="flex flex-col gap-2">{children}</div>
      )}
    </div>
  );
}

interface EmptyStateVisualProps extends React.ComponentPropsWithoutRef<'div'> {
  visual: React.ReactNode;
}
export function EmptyStateVisual({
  visual,
  className,
  ...rest
}: EmptyStateVisualProps) {
  return (
    <div
      className={twMerge('mx-auto h-8 w-8 text-ds-icon-secondary', className)}
      {...rest}
    >
      {visual}
    </div>
  );
}

export function ErrorStateVisual() {
  return (
    <EmptyStateVisual
      visual={
        <Icon className="h-full w-full text-ds-state-error">
          <IconUse id="close-circle-line" />
        </Icon>
      }
    />
  );
}
