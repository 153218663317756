import {
  SORT_SEARCH_PARAM_KEYS,
  SortSchema,
} from 'components/SortableColumnHeader';
import { z } from 'zod';

const ROLE_TYPE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
} as const;

export type RoleType = (typeof ROLE_TYPE)[keyof typeof ROLE_TYPE];

export const SAVED_ROLES_SEARCH_PARAM_KEY = {
  TERM: 'term',
  PAGE: 'page',
  ...SORT_SEARCH_PARAM_KEYS,
} as const;
export const SavedRolesSearchParamSchema = SortSchema.extend({
  page: z.coerce.number().int().positive().catch(1),
  term: z.string().catch(''),
});
