import { ButtonLink } from 'components/ds/Button';
import { Helmet } from 'react-helmet';
import { heading } from 'styles/heading';
import { text } from 'styles/text';

export function NotFound() {
  return (
    <>
      <Helmet title="404 - Page Not Found" />
      <div className="flex items-center justify-center">
        <div className="w-full max-w-prose items-center stack-y-6">
          <div className="stack-y-3">
            <h1
              className={heading({
                variant: { initial: '20', md: '30' },
                className: 'text-uppercase text-center text-ds-secondary-base',
              })}
            >
              404
            </h1>
            <h2
              className={heading({
                variant: { initial: '24', md: '36' },
                className: 'text-center',
              })}
            >
              Sorry, we can't find that page.
            </h2>
          </div>
          <p
            className={text({
              variant: { initial: '16', md: '18' },
              align: 'center',
              color: 'secondary',
              className: 'max-w-[50ch] text-balance',
            })}
          >
            The page you are looking for was moved, deleted, renamed, or may
            have never existed!
          </p>
          <div className="flex justify-center">
            <ButtonLink to="/" size={{ initial: 'sm', md: 'lg' }}>
              Go Back Home
            </ButtonLink>
          </div>
        </div>
      </div>
    </>
  );
}

export function NestedNotFound() {
  return (
    <>
      <Helmet title="404 - Page Not Found" />
      <div className="w-full max-w-prose stack-y-6">
        <div className="stack-y-3">
          <h1
            className={heading({
              variant: { initial: '20', md: '30' },
              className: 'text-uppercase text-ds-secondary-base',
            })}
          >
            404
          </h1>
          <h2
            className={heading({
              variant: { initial: '24', md: '36' },
            })}
          >
            hmm, we can't find that page.
          </h2>
        </div>
        <p
          className={text({
            variant: { initial: '16', md: '18' },
            color: 'secondary',
            className: 'max-w-[50ch] text-balance',
          })}
        >
          The page you are looking for was moved, deleted, renamed, or may have
          never existed!
        </p>
        <div className="flex">
          <ButtonLink to="/" size={{ initial: 'sm', md: 'lg' }}>
            Go Back Home
          </ButtonLink>
        </div>
      </div>
    </>
  );
}
