import { QueryClient } from '@tanstack/react-query';
import { PARTICIPANT_QUERIES } from 'api/participants';
import { USER_OPTIONS } from 'api/user/user';
import { Button, ButtonIcon } from 'components/ds/Button';
import { LoadingContainer } from 'components/ds/Spinner';
import { IconUse } from 'components/ds/icons/Icon';
import {
  PageActions,
  PageHeader,
  PageHeading,
} from 'components/layout-v2/PageLayout';
import { SaveRoleDialog } from 'modules/participant/utah/components/SaveRoleDialog';
import {
  MAX_RESULTS_PER_PAGE,
  SavedRolesDataTable,
} from 'modules/participant/utah/components/SavedRolesDataTable';
import { SavedSearchInput } from 'modules/participant/utah/components/SavedSearchInput';
import { getIsUtahParticipant } from 'modules/participant/utah/helpers/getIsUtahParticipant.helpers';
import { getSavedSearchParams } from 'modules/participant/utah/helpers/getSavedSearchParams.helpers';
import { PostHog } from 'posthog-js';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { LoaderFunctionArgs } from 'react-router-dom';
import { text } from 'styles/text';
import { notFound } from 'util/notFound';
import { getSkip } from 'util/pagination.helpers';

export async function savedLoader(
  args: LoaderFunctionArgs,
  queryClient: QueryClient,
  postHog?: PostHog
) {
  const searchParams = new URL(args.request.url).searchParams;
  const { term, orderBy, orderDir, page } = getSavedSearchParams(searchParams);
  const isUtahParticipant = await getIsUtahParticipant(queryClient, postHog);
  const user = await queryClient.ensureQueryData(USER_OPTIONS.user());

  // For now, this feature is only for participants in the Utah program track
  if (!isUtahParticipant) {
    return notFound();
  }

  queryClient.ensureQueryData(
    PARTICIPANT_QUERIES.savedRolesList(user.id.toFixed(), {
      params: {
        limit: MAX_RESULTS_PER_PAGE,
        skip: getSkip(page, MAX_RESULTS_PER_PAGE),
        term,
        orderBy: orderBy ?? undefined,
        orderDir: orderDir ?? undefined,
      },
    })
  );

  return null;
}
export function Saved() {
  return (
    <div className="content-container-full">
      <Helmet title="Saved" />
      <PageHeader>
        <div className="flex flex-col gap-3">
          <PageHeading>Saved</PageHeading>
          <p
            className={text({
              variant: '16',
              color: 'secondary',
            })}
          >
            Browse your lists of saved roles
          </p>
        </div>

        <PageActions>
          <SaveRoleDialog>
            <Button
              size={{
                initial: 'sm',
                md: 'default',
              }}
              prefix={
                <ButtonIcon>
                  <IconUse id="add-fill" />
                </ButtonIcon>
              }
            >
              Save New
            </Button>
          </SaveRoleDialog>
        </PageActions>
      </PageHeader>
      <div className="flex flex-col gap-6">
        <SavedSearchInput />
        <React.Suspense fallback={<LoadingContainer level="component" />}>
          <SavedRolesDataTable />
        </React.Suspense>
      </div>
    </div>
  );
}
