import { zodResolver } from '@hookform/resolvers/zod';
import { useSuspenseQuery } from '@tanstack/react-query';
import { PARTICIPANT_QUERIES } from 'api/participants';
import { USER_OPTIONS } from 'api/user/user';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from 'components/ds/Form';
import { Input } from 'components/ds/Input';
import { Textarea } from 'components/ds/Textarea';
import { SelectPayTypeFormField } from 'modules/participant/utah/components/CompensationFormField';
import { RankSelectFormField } from 'modules/participant/utah/components/RankFormField';
import { useUpdateSavedRole } from 'modules/participant/utah/hooks/useUpdateSavedRole';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const EDIT_SAVED_INTERNAL_ROLE_FORM_ID =
  'edit-saved-internal-role' as const;
export function EditSavedInternalRoleForm({
  savedRoleId,
  children,
  onEditSuccess,
  onEditFailure,
}: {
  savedRoleId: number;
  children?: ((isSubmitting: boolean) => React.ReactNode) | React.ReactNode;
  onEditSuccess?: (data: EditSavedInternalRoleFormOutput) => void;
  onEditFailure?: () => void;
}) {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const { data: savedRole } = useSuspenseQuery(
    PARTICIPANT_QUERIES.savedRoleDetail<'internal'>(
      user.id.toString(),
      savedRoleId
    )
  );
  const updateSavedRoleMutation = useUpdateSavedRole(savedRoleId);
  const form = useForm<EditSavedInternalRoleFormInput>({
    mode: 'onSubmit',
    resolver: zodResolver(EditSavedInternalRoleFormSchema),
    shouldUnregister: true,
    defaultValues: {
      compensation: savedRole.compensation ?? 0,
      payType: savedRole.payType,
      rank: savedRole.rank ?? 'None',
      notes: savedRole.notes ?? '',
    },
  });
  const { handleSubmit, control } = form;

  return (
    <>
      <Form {...form}>
        <form
          id={EDIT_SAVED_INTERNAL_ROLE_FORM_ID}
          onSubmit={handleSubmit((data) => {
            const editedInternalRole =
              data as unknown as EditSavedInternalRoleFormOutput;
            updateSavedRoleMutation.mutate(editedInternalRole, {
              onSuccess: () => {
                onEditSuccess?.(editedInternalRole);
              },
              onError: () => {
                onEditFailure?.();
              },
            });
          })}
        >
          <div className="flex flex-col gap-6">
            <div className="flex w-full flex-wrap gap-x-6 gap-y-6">
              <FormField
                control={control}
                name="rank"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full min-w-[280px] flex-1">
                      <FormLabel optional>Rank</FormLabel>
                      <RankSelectFormField {...field} />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={control}
                name="compensation"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full min-w-[280px] flex-1">
                      <FormLabel optional>Compensation</FormLabel>
                      <div className="flex items-center gap-3">
                        <div className="w-full flex-1">
                          <FormControl>
                            <Input
                              {...field}
                              type="number"
                              prefix={<span>$</span>}
                              prefixStyles
                              step=".01"
                              placeholder="0"
                            />
                          </FormControl>
                        </div>
                        {field.value != null && (
                          <div className="w-auto flex-shrink-0">
                            <FormField
                              control={control}
                              name="payType"
                              render={({ field }) => {
                                return <SelectPayTypeFormField {...field} />;
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </FormItem>
                  );
                }}
              />
            </div>
            <FormField
              control={control}
              name="notes"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel optional>Notes</FormLabel>
                    <div
                      className="grow-wrap after:px-3 after:py-2 after:content-[attr(data-content)_'_']"
                      data-content={field.value}
                    >
                      <FormControl>
                        <Textarea
                          className=""
                          {...field}
                          placeholder="Add notes..."
                        />
                      </FormControl>
                    </div>
                  </FormItem>
                );
              }}
            />
          </div>
        </form>
      </Form>
      {typeof children === 'function'
        ? children(updateSavedRoleMutation.isPending)
        : children}
    </>
  );
}

const EditSavedInternalRoleFormSchema = z.object({
  compensation: z.coerce
    .number({
      errorMap: () => ({
        message: 'Please enter a compensation amount',
      }),
    })
    .optional(),
  payType: z
    .enum(['Salary', 'Hourly'], {
      errorMap: () => ({
        message: 'Please select a pay type',
      }),
    })
    .optional(),
  notes: z.string().optional(),
  rank: z
    .enum(['None', 'Low', 'Medium', 'High'], {
      errorMap: () => ({
        message: 'Select a rank',
      }),
    })
    .transform((value) => {
      if (value === 'None') return null;
      return value;
    })
    .optional(),
});

type EditSavedInternalRoleFormInput = z.input<
  typeof EditSavedInternalRoleFormSchema
>;

type EditSavedInternalRoleFormOutput = z.output<
  typeof EditSavedInternalRoleFormSchema
>;
