import { useSuspenseQuery } from '@tanstack/react-query';
import { USER_OPTIONS } from 'api/user/user';
import { UTAH_PROGRAM_ID } from 'modules/participant/utah/utah.constants';
import { usePostHog } from 'posthog-js/react';

export function useIsUtahParticipant() {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const isParticipant = user?.type === 'participant';
  const isUtahParticipant =
    user?.profile?.programs?.some(
      (program) => program.id === UTAH_PROGRAM_ID
    ) ?? false;
  const posthog = usePostHog();
  const canAccessUtahFeatures =
    posthog.isFeatureEnabled('release-utah-program') ?? false;

  return isParticipant && isUtahParticipant && canAccessUtahFeatures;
}
