import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { PARTICIPANT_KEYS, UpdateRoleBody } from 'api/participants';
import { USER_OPTIONS } from 'api/user/user';
import api from 'dataService/api';

export function useUpdateSavedRole(savedRoleId: number) {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UpdateRoleBody) => {
      const response = await api.patch(
        `/participants/${user.id}/saved/roles/${savedRoleId}`,
        data
      );
      return response.data;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: PARTICIPANT_KEYS.savedRoles(user.id.toString()),
      });
    },
  });
}
