import * as React from 'react';
import { twMerge } from 'tailwind-merge';

interface RankProps extends React.ComponentPropsWithoutRef<'svg'> {
  level?: RankType;
}
export function Rank({ level, className, ...rest }: RankProps) {
  return level ? (
    <RankBars level={level} className={className} {...rest} />
  ) : (
    <RankNone />
  );
}

interface RankBarsProps extends React.ComponentPropsWithoutRef<'svg'> {
  level: RankType;
}
function RankBars({ level, className, ...rest }: RankBarsProps) {
  const value = RANK_BAR_VALUE[level];

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={twMerge('h-5 w-5 text-current', className)}
      width="20"
      height="20"
      {...rest}
    >
      <path
        data-filled={value >= 1}
        className="data-[filled=false]:fill-ds-icon-primary/30 data-[filled=true]:fill-ds-icon-primary"
        d="M4 13h4v6H4z"
      />
      <path
        className="data-[filled=false]:fill-ds-icon-primary/30 data-[filled=true]:fill-ds-icon-primary"
        data-filled={value >= 2}
        d="M10 9h4v10h-4z"
      />
      <path
        className="data-[filled=false]:fill-ds-icon-primary/30 data-[filled=true]:fill-ds-icon-primary"
        data-filled={value >= 3}
        d="M16 5h4v14h-4z"
      />
    </svg>
  );
}

function RankNone({
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={twMerge('h-5 w-5 text-current', className)}
      width="20"
      height="20"
      {...rest}
    >
      <path className="fill-ds-icon-primary/30" d="M4 11h4v2H4z" />
      <path className="fill-ds-icon-primary/30" d="M10 11h4v2h-4z" />
      <path className="fill-ds-icon-primary/30" d="M16 11h4v2h-4z" />
    </svg>
  );
}

const RANK_BAR_VALUE = {
  Low: 1,
  Medium: 2,
  High: 3,
};

type RankType = 'Low' | 'Medium' | 'High';
