import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { PARTICIPANT_KEYS } from 'api/participants';
import { USER_OPTIONS } from 'api/user/user';
import api from 'dataService/api';
import { SAVED_ROLES_EVENTS } from 'modules/participant/utah/constants/events.constants';
import { usePostHog } from 'posthog-js/react';
import { useSWRConfig } from 'swr';

export function useRemoveSavedRoleById({
  savedRoleId,
}: {
  savedRoleId: number;
}) {
  const postHog = usePostHog();
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const queryClient = useQueryClient();
  const { mutate } = useSWRConfig();

  return useMutation({
    mutationFn: async () => {
      postHog.capture(SAVED_ROLES_EVENTS.SAVE_ROLE_DELETE_INITIATED);

      const res = await api.delete(
        `/participants/${user.id}/saved/roles/${savedRoleId}`
      );

      return res.data;
    },
    onSuccess: () => {
      mutate(checkJobKey);

      return queryClient.invalidateQueries({
        queryKey: PARTICIPANT_KEYS.savedRoles(user.id.toString()),
      });
    },
  });
}

const checkJobKey = (args: string | [string, unknown]) => {
  if (typeof args === 'string') return false;

  const [key] = args;

  if (key.startsWith('jobs')) {
    return true;
  }
};
