import { RankType } from 'api/participants';
import { FormControl } from 'components/ds/Form';
import { Rank } from 'components/ds/Rank';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ds/Select';
import * as React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

export const RankSelectFormField = React.forwardRef<
  React.ComponentRef<typeof SelectTrigger>,
  Omit<ControllerRenderProps, 'ref' | 'value'> & {
    value?: RankType | 'None';
  }
>(({ onChange, value, ...rest }, ref) => {
  return (
    <Select defaultValue={value} onValueChange={onChange} value={value}>
      <FormControl>
        <SelectTrigger {...rest} ref={ref}>
          <span className="!inline-flex gap-1">
            <span aria-hidden className="block w-auto shrink-0">
              {value === 'None' || value == null ? (
                <Rank />
              ) : (
                <Rank level={value} />
              )}
            </span>
            <span className="block">
              <SelectValue placeholder="Select a rank" />
            </span>
          </span>
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <SelectItem prefix={<Rank />} value="None">
          None
        </SelectItem>
        <SelectItem prefix={<Rank level="Low" />} value="Low">
          Low
        </SelectItem>
        <SelectItem prefix={<Rank level="Medium" />} value="Medium">
          Medium
        </SelectItem>
        <SelectItem prefix={<Rank level="High" />} value="High">
          High
        </SelectItem>
      </SelectContent>
    </Select>
  );
});
RankSelectFormField.displayName = 'RankFormField';
