import {
  SAVED_ROLES_SEARCH_PARAM_KEY,
  SavedRolesSearchParamSchema,
} from 'modules/participant/utah/constants/savedRoles.constants';

export function getSavedSearchParams(searchParams: URLSearchParams) {
  const term = searchParams.get(SAVED_ROLES_SEARCH_PARAM_KEY.TERM);
  const orderBy = searchParams.get(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_BY);
  const orderDir = searchParams.get(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_DIR);
  const page = searchParams.get(SAVED_ROLES_SEARCH_PARAM_KEY.PAGE);

  return SavedRolesSearchParamSchema.parse({ term, orderDir, orderBy, page });
}
