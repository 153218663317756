import { FormControl } from 'components/ds/Form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ds/Select';
import * as React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

export const SelectPayTypeFormField = React.forwardRef<
  React.ComponentRef<typeof SelectTrigger>,
  Omit<ControllerRenderProps, 'ref' | 'value'> & {
    value?: 'Salary' | 'Hourly';
  }
>(({ value, onChange, ...rest }, ref) => {
  return (
    <Select defaultValue={value} onValueChange={onChange} value={value}>
      <FormControl>
        <SelectTrigger {...rest} ref={ref}>
          <SelectValue placeholder="Pay Type" />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <SelectItem value="Salary">Per Year</SelectItem>
        <SelectItem value="Hourly">Per Hour</SelectItem>
      </SelectContent>
    </Select>
  );
});
