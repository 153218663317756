import {
  Dialog,
  DialogContentLayout,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ds/Dialog';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from 'components/ds/Drawer';
import { EmptyState, ErrorStateVisual } from 'components/ds/EmptyState';
import { LoadingContainer } from 'components/ds/Spinner';
import useMediaQuery from 'hooks/useMediaQuery';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export function ResponsiveDialogLayout({
  children,
  title,
  description,
  dialogContent,
  drawerContent,
}: {
  title: React.ReactNode;
  description: React.ReactNode;
  dialogContent: (
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => React.ReactNode;
  drawerContent: (
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => React.ReactNode;
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const isAboveTablet = useMediaQuery('(min-width: 768px)');

  return isAboveTablet ? (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContentLayout className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <LazyDialogContent>{dialogContent(setIsOpen)}</LazyDialogContent>
      </DialogContentLayout>
    </Dialog>
  ) : (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DrawerTrigger asChild>{children}</DrawerTrigger>
      <DrawerContent className="h-auto max-h-[90dvh]">
        <div className="overflow-y-auto">
          <DrawerHeader>
            <DrawerTitle>{title}</DrawerTitle>
            <DrawerDescription>{description}</DrawerDescription>
          </DrawerHeader>
          <div className="p-4">
            <LazyDialogContent>{drawerContent(setIsOpen)}</LazyDialogContent>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

function LazyDialogContent({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary
      fallbackRender={() => {
        return (
          <EmptyState
            visual={<ErrorStateVisual />}
            title="Failed to load data"
          />
        );
      }}
    >
      <React.Suspense fallback={<LoadingContainer level="component" />}>
        {children}
      </React.Suspense>
    </ErrorBoundary>
  );
}
