import { useUserProfile } from 'api/user/user';
import { Auth } from 'aws-amplify';
import { useGlobalModal } from 'components/Modal';
import config from 'config';
import React, { useContext, useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';

interface SocketProviderProps {
  children?: React.ReactNode;
}
export function SocketProvider({ children }: SocketProviderProps) {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { data: user } = useUserProfile();

  useEffect(() => {
    if (!user) return;
    let newSocket: Socket | null = null;

    async function getAccessToken() {
      const session = await Auth.currentSession();
      return session.getAccessToken().getJwtToken();
    }
    (async function connect() {
      if (config.socketURL) {
        const token = await getAccessToken();
        newSocket = io(config.socketURL, {
          reconnection: true,
          reconnectionAttempts: 3,
          transports: ['websocket'],
          auth: {
            token,
          },
          query: {
            token,
          },
        });
      }
      newSocket?.on?.('connect', () => {
        setSocket(newSocket);
      });
    })();

    return () => {
      setSocket(null);
      newSocket?.disconnect?.();
    };
  }, [user]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
}
SocketProvider.displayName = 'SocketProvider';

const SocketContext = React.createContext<{ socket: Socket | null }>({
  socket: null,
});

export function useSocket() {
  const { socket } = useContext(SocketContext);
  return socket;
}

/**
 * Custom hook for handling socket events.
 *
 * @template TEvent - The type of socket event.
 * @param {TEvent} event - The socket event to listen for.
 * @param {(data: SocketEvent[TEvent]) => void} callback - The callback function
 *   to be executed when the event is triggered. Make sure to use `useCallback`
 *   to prevent unnecessary re-renders.
 */
export function useSocketEvent<TEvent extends keyof SocketPayloads>(
  event: TEvent,
  callback: (data: SocketPayloads[TEvent]) => void
) {
  const socket = useSocket();

  useEffect(() => {
    if (!socket) return;

    socket.on(event, callback);

    return () => {
      socket.off(event, callback);
    };
  }, [socket, event, callback]);
}

interface SocketPayloads {
  newNotifications: unknown;
}

/**
 * Adds a data lock on the server based on the given path. Attempts to destroy
 * the lock on unmount
 *
 * @param {string} path The path to create a lock for.
 * @param {function} [callback] If a callback is set it will be called and
 *   passed the response from the socket call. If no callback is passed The lock
 *   modal will be displayed if there is already a lock.
 */
export const useDataLock = (
  path: string | boolean,
  callback?: (resp: any) => void
) => {
  let socket: Socket | null = null;
  const { toggleModal } = useGlobalModal();

  useEffect(() => {
    let mounted = true;
    if (!path) return;

    socket?.emit?.('addLock', path, (resp: any) => {
      if (!mounted) return;

      if (callback) {
        callback(resp);
      } else if (!resp || !resp.success) {
        toggleModal(false);
      }
    });

    return () => {
      mounted = false;
      socket?.emit?.('destroyLock', path);
    };
  }, [path, socket, toggleModal, callback]);
};
