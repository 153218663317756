import { heading } from 'styles/heading';

export const formLabelStyles = 'md:text-base' as const;
export const inputStyles = 'md:text-base md:h-12' as const;
export const errorMessageStyles = 'md:text-base' as const;
export const formRowStyles =
  'flex w-full flex-wrap gap-x-6 gap-y-6 max-w-prose flex-col' as const;
export const formItemStyles = 'w-full min-w-[280px] flex-1' as const;
export const checkboxStyles = 'md:h-5 md:w-5' as const;
export const radioStyles = 'md:h-5 md:w-5' as const;
export const selectionGroupStyles =
  'flex w-full min-w-[280px] flex-1 !flex-row items-center gap-3' as const;

export const pageTitle = heading({
  variant: {
    initial: '24',
    md: '30',
  },
  className: 'font-medium',
});
