import * as React from 'react';
import { heading } from 'styles/heading';
import { text } from 'styles/text';
import { twMerge } from 'tailwind-merge';
import { Drawer as DrawerPrimitive } from 'vaul';

/**
 * This is a mobile-specific component that slides in from the bottom of the
 * screen and is dismissible.
 *
 * It's best to use this component to handle mobile-specific versions of other
 * components like `Dialog`, `Modal` or `Sheet`.
 */
export function Drawer({
  /* Intentionally plucking this prop out to prevent it from being passed to the `DrawerPrimitive.Root` component. It does not work with our application layout. */
  shouldScaleBackground,
  ...rest
}: React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Root>) {
  return <DrawerPrimitive.Root shouldScaleBackground={false} {...rest} />;
}

export const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

export const DrawerClose = DrawerPrimitive.Close;

export const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...rest }, ref) => {
  return (
    <DrawerPrimitive.Overlay
      ref={ref}
      className={twMerge('fixed inset-0 z-50 bg-ds-neutral-900/80', className)}
      {...rest}
    />
  );
});
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

export const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...rest }, ref) => {
  return (
    <DrawerPortal>
      <DrawerOverlay />
      <DrawerPrimitive.Content
        ref={ref}
        className={twMerge(
          'fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border bg-ds-bg-foundation',
          className
        )}
        {...rest}
      >
        <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted" />
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  );
});
DrawerContent.displayName = 'DrawerContent';

export function DrawerHeader({
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={twMerge(
        'grid gap-1.5 p-4 text-center sm:text-left',
        className
      )}
      {...rest}
    />
  );
}

export function DrawerFooter({
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={twMerge('mt-auto flex flex-col gap-2 p-4', className)}
      {...rest}
    />
  );
}

export const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...rest }, ref) => {
  return (
    <DrawerPrimitive.Title
      ref={ref}
      className={heading({ variant: '20', className })}
      {...rest}
    />
  );
});
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

export const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...rest }, ref) => {
  return (
    <DrawerPrimitive.Description
      ref={ref}
      className={text({
        variant: '14',
        color: 'secondary',
        className,
      })}
      {...rest}
    />
  );
});
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;
