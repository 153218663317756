import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ds/DropdownMenu';
import {
  TableHead,
  TableHeadAction,
  TableHeadProps,
} from 'components/ds/Table';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { useComponentSearchParams } from 'context/ComponentSearchParams';
import { parseSearchParams, stringify } from 'util/searchParams.helpers';
import { z } from 'zod';

interface SortableColumnHeaderProps extends TableHeadProps {
  orderDir: OrderDirection | null;
  onDirectionChange: (orderDir: OrderDirection | null) => void;
}
export function SortableColumnHeader({
  orderDir,
  onDirectionChange,
  children,
  ...rest
}: SortableColumnHeaderProps) {
  const isAscending = orderDir === ORDER_DIR_VALUES.ASC;
  const isDescending = orderDir === ORDER_DIR_VALUES.DESC;

  return (
    <TableHead {...rest}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <TableHeadAction
            suffix={
              isAscending ? (
                <Icon className="h-3 w-3">
                  <IconUse id="arrow-up-line" />
                </Icon>
              ) : isDescending ? (
                <Icon className="h-3 w-3">
                  <IconUse id="arrow-down-line" />
                </Icon>
              ) : undefined
            }
          >
            {children}
          </TableHeadAction>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[128px] shadow-sm" align="start">
          <DropdownMenuGroup>
            <DropdownMenuLabel className="sr-only">Sort By</DropdownMenuLabel>
            <DropdownMenuItem asChild>
              <button
                className="w-full stack-x-2"
                onClick={() => onDirectionChange(ORDER_DIR_VALUES.ASC)}
              >
                <Icon className="h-4 w-4">
                  <IconUse id="arrow-up-line" />
                </Icon>
                Asc
              </button>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <button
                className="w-full stack-x-2"
                onClick={() => onDirectionChange(ORDER_DIR_VALUES.DESC)}
              >
                <Icon className="h-4 w-4">
                  <IconUse id="arrow-down-line" />
                </Icon>
                Desc
              </button>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuLabel className="sr-only">Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onSelect={() => onDirectionChange(null)}
              className="w-full stack-x-2"
            >
              <Icon className="h-4 w-4">
                <IconUse id="loop-right-line" />
              </Icon>
              Reset
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </TableHead>
  );
}

export const ORDER_DIR_VALUES = {
  ASC: 'asc',
  DESC: 'desc',
} as const;
export type OrderDirection =
  (typeof ORDER_DIR_VALUES)[keyof typeof ORDER_DIR_VALUES];

export const SORT_SEARCH_PARAM_KEYS = {
  ORDER_BY: 'orderBy',
  ORDER_DIR: 'orderDir',
} as const;

export const SortSchema = z.object({
  [SORT_SEARCH_PARAM_KEYS.ORDER_BY]: z.string().nullable().catch(null),
  [SORT_SEARCH_PARAM_KEYS.ORDER_DIR]: z
    .union([z.literal(ORDER_DIR_VALUES.ASC), z.literal(ORDER_DIR_VALUES.DESC)])
    .catch(ORDER_DIR_VALUES.DESC),
});

export function ComponentScopedSortableColumnHeader({
  orderById,
  children,
}: {
  orderById: string;
  children?: React.ReactNode;
}) {
  const { paramKey, params } = useComponentSearchParams();
  const [searchParams, setSearchParams] = params;
  const parsedParams = parseSearchParams(searchParams, paramKey);
  const sort = SortSchema.optional().parse(parsedParams?.sort);
  const { orderDir: currentOrderDir, orderBy: currentOrderBy } = sort ?? {};
  const isCurrentOrderBy = currentOrderBy === orderById;

  function handleDirectionChange(orderDir: OrderDirection | null) {
    setSearchParams((searchParams) => {
      const previousParams = parseSearchParams(searchParams, paramKey);

      if (orderDir === null) {
        searchParams.set(
          paramKey,
          stringify({
            ...previousParams,
            sort: undefined,
          })
        );

        return searchParams;
      }

      searchParams.set(
        paramKey,
        stringify({
          ...previousParams,
          sort: { orderBy: orderById, orderDir },
        })
      );

      return searchParams;
    });
  }

  if (!isCurrentOrderBy) {
    return (
      <SortableColumnHeader
        onDirectionChange={handleDirectionChange}
        orderDir={null}
      >
        {children}
      </SortableColumnHeader>
    );
  }

  return (
    <SortableColumnHeader
      onDirectionChange={handleDirectionChange}
      orderDir={currentOrderDir ?? null}
    >
      {children}
    </SortableColumnHeader>
  );
}
