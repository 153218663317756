import * as React from 'react';
import { focusRingStyles } from 'styles/focus';
import { twMerge } from 'tailwind-merge';

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.ComponentPropsWithoutRef<'textarea'>
>(({ className, ...rest }, ref) => {
  return (
    <textarea
      className={focusRingStyles({
        variant: 'input',
        className: twMerge(
          'flex min-h-[60px] w-full rounded-md border border-ds-stroke-tertiary bg-ds-field-1 px-3 py-2 text-sm text-ds-text-primary transition-all placeholder:text-ds-text-placeholder read-only:border-ds-stroke-tertiary read-only:bg-ds-neutral-0 read-only:text-ds-text-tertiary read-only:shadow-none disabled:cursor-not-allowed disabled:bg-ds-field-1 disabled:opacity-50 aria-[invalid=true]:border-ds-state-error aria-[invalid=true]:ring-2 aria-[invalid=true]:ring-ds-red-light',
          className
        ),
      })}
      ref={ref}
      {...rest}
    />
  );
});
Textarea.displayName = 'Textarea';
