import { tv } from 'tailwind-variants';

export const focusRingStyles = tv({
  base: 'focus:outline-none focus:ring-2 focus-visible:ring-2 focus-within:ring-2',
  variants: {
    variant: {
      default:
        'focus-visible:ring-ds-stroke-tertiary focus-visible:ring-offset-2 ring-offset-ds-bg-foundation focus-within:ring-ds-stroke-tertiary focus-within:ring-offset-2 focus-within:ring-offset-ds-bg-foundation',
      input:
        'focus-within:border-ds-neutral-400 focus-within:bg-ds-bg-foundation focus-within:text-ds-text-primary focus-within:ring-ds-stroke-secondary focus:border-ds-neutral-400 focus:bg-ds-bg-foundation focus:text-ds-text-primary focus:ring-ds-stroke-secondary',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
