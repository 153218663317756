import { position } from 'polished';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';
import { hover } from 'styles/helpers';
import UnstyledButton from './UnstyledButton';
import Input from './forms/Input';

const Form = styled.form`
  max-width: 320px;
  padding: 24px 0 ${(props) => (props.noBottomSpace ? 0 : '40px')};
`;

const SearchInput = styled(Input)`
  input {
    padding-left: 32px;
    background-image: url('/images/search-icon.svg');
    background-position: 8px center;
    background-size: 15px;
    background-repeat: no-repeat;
  }
`;

const ClearSearch = styled(UnstyledButton)`
  ${position('absolute', 0, 0, null, null)}
  padding: 10px 8px;
  color: var(--gray-text);

  ${hover(`
    color: var(--primary-green);
  `)}

  svg {
    display: block;
  }
`;

/**
 * @returns {object} Search
 * @returns {string} Search.searchTerm
 * @returns {function} Search.searchSubmit
 */
export const useSearch = () => {
  const [submittedSearchTerm, setSearchTerm] = useState();

  /**
   * Handle search submit
   *
   * @param {object} event - Browser event. If no event is passed, the search
   *   term was cleared.
   */
  const searchSubmit = (event) => {
    if (event) {
      event.preventDefault();
      const { value } = event.target.querySelector('input');
      if (value === '') return;
      setSearchTerm(value);
    } else {
      setSearchTerm(false);
    }
  };

  return {
    searchTerm: submittedSearchTerm,
    searchSubmit,
  };
};

const Search = ({
  onSubmit,
  onFocus,
  label,
  className,
  inputClassName,
  noBottomSpace,
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Form
      onSubmit={onSubmit}
      className={className}
      noBottomSpace={noBottomSpace}
    >
      <div style={{ position: 'relative' }}>
        <SearchInput
          type="search"
          placeholder="Search..."
          name="search"
          label={label}
          autoComplete="off"
          small
          hideLabel
          className={inputClassName}
          value={inputValue}
          onFocus={onFocus}
          onChange={(e) => {
            const val = e.target.value;
            setInputValue(val);
            if (val === '') {
              onSubmit();
            }
          }}
        />
        {inputValue !== '' ? (
          <ClearSearch
            type="button"
            aria-label="Clear search"
            onClick={() => {
              setInputValue('');
              onSubmit();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M8 2.6667C5.0545 2.6667 2.6666 5.0545 2.6666 8c0 2.9455 2.3879 5.3333 5.3334 5.3333S13.3333 10.9455 13.3333 8c0-2.9455-2.3878-5.3333-5.3333-5.3333zM1.3333 8c0-3.682 2.9848-6.6667 6.6667-6.6667 3.6818 0 6.6666 2.9848 6.6666 6.6667 0 3.6819-2.9848 6.6667-6.6666 6.6667-3.682 0-6.6667-2.9848-6.6667-6.6667zm3.862-2.8047a.6667.6667 0 01.9428 0L8 7.0572l1.862-1.862a.6666.6666 0 11.9427.9429L8.9427 8l1.862 1.862a.6667.6667 0 01-.9428.9427L8 8.9427l-1.8619 1.862a.6666.6666 0 11-.9428-.9428L7.0572 8l-1.862-1.862a.6667.6667 0 010-.9427z"
                clipRule="evenodd"
              />
            </svg>
          </ClearSearch>
        ) : null}
      </div>
    </Form>
  );
};

Search.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  noBottomSpace: PropTypes.bool,
  onFocus: PropTypes.func,
};

export default Search;
