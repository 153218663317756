import * as SelectPrimitive from '@radix-ui/react-select';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import * as React from 'react';
import { focusRingStyles } from 'styles/focus';
import { twMerge } from 'tailwind-merge';

export const Select = SelectPrimitive.Root;

export const SelectGroup = SelectPrimitive.Group;

export const SelectValue = SelectPrimitive.Value;

export const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...rest }, ref) => {
  return (
    <SelectPrimitive.Trigger
      ref={ref}
      className={focusRingStyles({
        variant: 'input',
        className: twMerge(
          'group flex h-10 w-full items-center justify-between whitespace-nowrap rounded-md border border-ds-stroke-tertiary bg-ds-field-1 px-3 py-2 text-left text-sm text-ds-text-primary disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid=true]:border-ds-state-error aria-[invalid=true]:ring-2 aria-[invalid=true]:ring-ds-red-light disabled:aria-[readonly=true]:border-ds-stroke-tertiary disabled:aria-[readonly=true]:bg-ds-bg-foundation disabled:aria-[readonly=true]:text-ds-text-tertiary disabled:aria-[readonly=true]:opacity-100 data-[placeholder]:text-ds-text-placeholder [&>span]:line-clamp-1 [&>span]:inline-block [&>span]:min-w-0 [&>span]:flex-1 [&>span]:truncate',
          className
        ),
      })}
      {...rest}
    >
      {children}
      <SelectPrimitive.Icon asChild>
        <Icon className="shrink-0 text-ds-icon-secondary group-focus:text-current group-disabled:group-aria-[readonly=true]:text-ds-icon-tertiary">
          <IconUse id="arrow-down-s-line" />
        </Icon>
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  );
});
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

export const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...rest }, ref) => {
  return (
    <SelectPrimitive.ScrollUpButton
      ref={ref}
      className={twMerge(
        'flex cursor-default items-center justify-center py-1 text-ds-text-secondary transition-colors hover:text-ds-text-primary',
        className
      )}
      {...rest}
    >
      <Icon>
        <IconUse id="arrow-up-s-line" />
      </Icon>
    </SelectPrimitive.ScrollUpButton>
  );
});
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

export const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...rest }, ref) => {
  return (
    <SelectPrimitive.ScrollDownButton
      ref={ref}
      className={twMerge(
        'flex cursor-default items-center justify-center py-1 text-ds-text-secondary transition-colors hover:text-ds-text-primary',
        className
      )}
      {...rest}
    >
      <Icon>
        <IconUse id="arrow-down-s-line" />
      </Icon>
    </SelectPrimitive.ScrollDownButton>
  );
});
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

export const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(
  (
    { className, children, sideOffset = 8, position = 'popper', ...rest },
    ref
  ) => {
    return (
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          ref={ref}
          className={twMerge(
            'relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border border-ds-stroke-tertiary bg-ds-bg-foundation text-ds-text-primary shadow-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            position === 'popper' &&
              'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
            className
          )}
          sideOffset={sideOffset}
          position={position}
          {...rest}
        >
          <SelectScrollUpButton />
          <SelectPrimitive.Viewport
            className={twMerge(
              'p-1',
              position === 'popper' &&
                'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'
            )}
          >
            {children}
          </SelectPrimitive.Viewport>
          <SelectScrollDownButton />
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    );
  }
);
SelectContent.displayName = SelectPrimitive.Content.displayName;

export const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...rest }, ref) => {
  return (
    <SelectPrimitive.Label
      ref={ref}
      className={twMerge('px-2 py-1.5 text-sm font-medium', className)}
      {...rest}
    />
  );
});
SelectLabel.displayName = SelectPrimitive.Label.displayName;

export const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  Omit<
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>,
    'prefix'
  > & {
    prefix?: React.ReactElement;
  }
>(({ prefix, className, children, ...rest }, ref) => {
  return (
    <SelectPrimitive.Item
      ref={ref}
      className={twMerge(
        'relative flex w-full cursor-default select-none items-center gap-1 overflow-clip rounded p-2 text-sm text-ds-text-secondary outline-none focus:bg-ds-bg-weaker focus:text-ds-text-primary data-[disabled]:pointer-events-none data-[state=checked]:text-ds-text-primary data-[disabled]:opacity-50',
        className
      )}
      {...rest}
    >
      {prefix != null && (
        <span className="block w-auto shrink-0">{prefix}</span>
      )}
      <span className="block min-w-0 flex-1 truncate">
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </span>
      <span className="flex h-4 w-4 shrink-0 items-center justify-center">
        <SelectPrimitive.ItemIndicator>
          <Icon className="h-4 w-4">
            <IconUse id="checkbox-check" />
          </Icon>
        </SelectPrimitive.ItemIndicator>
      </span>
    </SelectPrimitive.Item>
  );
});
SelectItem.displayName = SelectPrimitive.Item.displayName;

export const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...rest }, ref) => {
  return (
    <SelectPrimitive.Separator
      ref={ref}
      className={twMerge('-mx-1 my-1 h-px bg-ds-bg-weaker', className)}
      {...rest}
    />
  );
});
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;
