import { focusRingStyles } from 'styles/focus';
import { tv } from 'tailwind-variants';

export const navAction = tv({
  slots: {
    container: focusRingStyles({
      className:
        'group flex w-full items-center rounded-lg text-left text-sm leading-tight text-ds-text-primary hover:bg-ds-bg-weaker focus:bg-ds-bg-weaker overflow-clip transition-colors',
    }),
    text: 'block',
  },
  variants: {
    state: {
      expanded: {
        container: 'px-3 py-2',
        text: 'opacity-100',
      },
      collapsed: {
        container: 'w-10 h-10',
        text: 'opacity-0 pointer-events-none',
      },
    },
  },
  defaultVariants: {
    state: 'expanded',
  },
});
