import { QueryClient } from '@tanstack/react-query';
import { USER_OPTIONS } from 'api/user/user';
import { UTAH_PROGRAM_ID } from 'modules/participant/utah/utah.constants';
import { PostHog } from 'posthog-js';

export async function getIsUtahParticipant(
  queryClient: QueryClient,
  posthog?: PostHog
) {
  const user = await queryClient.ensureQueryData(USER_OPTIONS.user());
  const isParticipant = user?.type === 'participant';
  const isUtahParticipant =
    user?.profile?.programs?.some(
      (program) => program.id === UTAH_PROGRAM_ID
    ) ?? false;
  const canAccessUtahFeatures = posthog?.isFeatureEnabled(
    'release-utah-program'
  );

  return isParticipant && isUtahParticipant && canAccessUtahFeatures;
}
