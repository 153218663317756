import * as SelectPrimitive from '@radix-ui/react-select';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  PARTICIPANT_QUERIES,
  PayType,
  RANKS,
  RankType,
  SavedRolesResponse,
} from 'api/participants';
import { USER_OPTIONS } from 'api/user/user';
import {
  OrderDirection,
  SortableColumnHeader,
} from 'components/SortableColumnHeader';
import { CompanyAvatar } from 'components/ds/Avatar';
import { Badge } from 'components/ds/Badge';
import { Button, ButtonIcon } from 'components/ds/Button';
import { EmptyState, EmptyStateVisual } from 'components/ds/EmptyState';
import {
  Pagination,
  PaginationStats,
} from 'components/ds/Pagination/Pagination';
import { Rank } from 'components/ds/Rank';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
} from 'components/ds/Select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ds/Table';
import { toast } from 'components/ds/Toast/Toast';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { EditSavedRoleNotesDialog } from 'modules/participant/utah/components/EditSavedRoleNotesDialog';
import { SaveRoleDialog } from 'modules/participant/utah/components/SaveRoleDialog';
import { SavedRolesRowActions } from 'modules/participant/utah/components/SavedRolesRowActions';
import {
  RoleType,
  SAVED_ROLES_SEARCH_PARAM_KEY,
} from 'modules/participant/utah/constants/savedRoles.constants';
import { getSavedSearchParams } from 'modules/participant/utah/helpers/getSavedSearchParams.helpers';
import { useUpdateSavedRole } from 'modules/participant/utah/hooks/useUpdateSavedRole';
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { focusRingStyles } from 'styles/focus';
import { text } from 'styles/text';
import { twMerge } from 'tailwind-merge';
import { VariantProps, tv } from 'tailwind-variants';
import {
  getPageTotal,
  getRowEnd,
  getRowStart,
  getSkip,
} from 'util/pagination.helpers';

export const MAX_RESULTS_PER_PAGE = 50;
export function SavedRolesDataTable() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { term, orderBy, orderDir, page } = getSavedSearchParams(searchParams);
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());

  const params = React.useMemo(() => {
    return {
      params: {
        limit: MAX_RESULTS_PER_PAGE,
        skip: getSkip(page, MAX_RESULTS_PER_PAGE),
        term,
        orderBy: orderBy ?? undefined,
        orderDir: orderDir ?? undefined,
      },
    };
  }, [term, orderBy, orderDir, page]);
  const deferredParams = React.useDeferredValue(params);
  const isDeferred = params !== deferredParams;

  const { data: saved } = useSuspenseQuery(
    PARTICIPANT_QUERIES.savedRolesList(user.id.toFixed(), deferredParams)
  );
  const { items: roles, total } = saved ?? {};
  const pageTotal = getPageTotal(total, MAX_RESULTS_PER_PAGE);
  const rowNumStart = getRowStart(page, MAX_RESULTS_PER_PAGE, total);
  const rowNumEnd = getRowEnd(page, MAX_RESULTS_PER_PAGE, total);

  const table = useReactTable({
    columns,
    data: roles,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row, _i) => `${row.id}`,
    manualSorting: true,
    manualPagination: true,
  });

  return (
    <div className="flex flex-col gap-6">
      <div
        className={twMerge(
          'transition-opacity',
          isDeferred ? 'cursor-wait opacity-50' : ''
        )}
      >
        <Table
          className={twMerge(
            'grid grid-cols-[1fr_auto_60px] items-center transition-opacity lg:grid-cols-[56px_1fr_15%_25%_10%_60px]',
            isDeferred ? 'pointer-events-none' : ''
          )}
        >
          <TableHeader className="z-0 contents">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="contents">
                {headerGroup.headers.map((header) => (
                  <React.Fragment key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="contents">
            {table.getRowModel().rows?.length !== 0 ? (
              <>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id} className="contents">
                      {row.getVisibleCells().map((cell) => (
                        <React.Fragment key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  );
                })}
                <TableRow className="contents">
                  <td className="col-span-full py-2">
                    <SaveRoleDialog>
                      <button
                        className={focusRingStyles({
                          className:
                            'flex items-center gap-2 rounded-md px-3 py-2 text-ds-text-secondary transition-colors hover:bg-ds-bg-weaker hover:text-ds-text-primary active:bg-ds-bg-soft lg:pr-6',
                        })}
                      >
                        <span className="inline-flex w-auto justify-center lg:w-[56px]">
                          <Icon className="h-5 w-5">
                            <IconUse id="add-fill" />
                          </Icon>
                        </span>
                        <span className="text-sm font-medium leading-none">
                          Save new role
                        </span>
                      </button>
                    </SaveRoleDialog>
                  </td>
                </TableRow>
              </>
            ) : (
              <TableRow className="contents">
                <TableCell className="col-span-full">
                  <EmptyState
                    visual={
                      <EmptyStateVisual
                        visual={
                          <Icon className="h-full w-full">
                            <IconUse id="database-2-line" />
                          </Icon>
                        }
                      />
                    }
                    title="No Saved Roles found"
                    description="Try adjusting your search or save a new role."
                  >
                    <SaveRoleDialog>
                      <Button
                        size="sm"
                        variant="secondary"
                        prefix={
                          <ButtonIcon>
                            <IconUse id="add-fill" />
                          </ButtonIcon>
                        }
                      >
                        Save Role
                      </Button>
                    </SaveRoleDialog>
                  </EmptyState>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex-wrap justify-between stack-x-6">
        <PaginationStats
          rowNumStart={rowNumStart}
          rowNumEnd={rowNumEnd}
          total={total}
        >
          Saved Roles
        </PaginationStats>
        <Pagination
          currentPage={page}
          totalPages={pageTotal}
          siblingCount={1}
          onPageChange={(page) => {
            setSearchParams((searchParams) => {
              if (!page) {
                searchParams.delete(SAVED_ROLES_SEARCH_PARAM_KEY.PAGE);
                return searchParams;
              }

              searchParams.set(SAVED_ROLES_SEARCH_PARAM_KEY.PAGE, page);

              return searchParams;
            });
          }}
        />
      </div>
    </div>
  );
}
const columnHelper = createColumnHelper<SavedRolesResponse['items'][number]>();
const columns = [
  columnHelper.accessor((row) => row, {
    id: 'logo',
    header: () => (
      <TableHead className="hidden items-center lg:flex">
        <span className="sr-only">Company Logo</span>
      </TableHead>
    ),
    cell: ({ getValue }) => {
      const row = getValue();
      const { company } = row;
      const companyLogo =
        typeof company !== 'string' ? company.logo?.location ?? '' : '';
      const companyName = typeof company === 'string' ? company : company.name;

      if (companyLogo?.length === 0)
        return (
          <TableCell className="hidden self-start lg:table-cell">
            <span className="sr-only">Logo not available</span>
          </TableCell>
        );

      return (
        <TableCell className="hidden self-start lg:table-cell">
          <CompanyAvatar
            alt={`${companyName} logo`}
            fallback={[companyName]}
            size={{
              initial: 'sm',
              lg: 'base',
            }}
            logo={companyLogo}
          />
        </TableCell>
      );
    },
  }),
  columnHelper.accessor((row) => row, {
    id: 'role',
    header: () => {
      return (
        <TableHead className="flex items-center rounded-l-md lg:rounded-none">
          Role
        </TableHead>
      );
    },
    cell: ({ getValue }) => {
      const row = getValue();
      const { company, type, job, isRemote, compensation, payType } = row;
      const jobTitle = typeof job === 'string' ? job : job.title;

      switch (type) {
        case 'internal': {
          return (
            <RoleCellLayout
              company={company.name}
              compensation={compensation}
              isRemote={isRemote}
              type={type}
              payType={payType}
            >
              {row.job?.url && row.job.url?.length > 0 ? (
                <Link
                  to={row.job.url}
                  className={text({
                    variant: {
                      initial: '14',
                      lg: '16',
                    },
                    className: focusRingStyles({
                      className:
                        'linkOverlay font-medium no-underline hover:opacity-75',
                    }),
                  })}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {jobTitle}
                </Link>
              ) : (
                <span
                  className={text({
                    variant: {
                      initial: '14',
                      lg: '16',
                    },
                    weight: 'medium',
                  })}
                >
                  {jobTitle}
                </span>
              )}
            </RoleCellLayout>
          );
        }
        case 'external': {
          return (
            <RoleCellLayout
              company={typeof company === 'string' ? company : company.name}
              compensation={compensation}
              isRemote={isRemote}
              type={type}
              payType={payType}
            >
              <span
                className={text({
                  variant: {
                    initial: '14',
                    lg: '16',
                  },
                  weight: 'medium',
                })}
              >
                {jobTitle}
              </span>
            </RoleCellLayout>
          );
        }
        default: {
          return <TableCell />;
        }
      }
    },
  }),
  columnHelper.accessor(
    (row) => ({ compensation: row.compensation, payType: row.payType }),
    {
      id: 'compensation',
      header: () => {
        return (
          <SavedRolesDataTableHeader
            orderById="compensation"
            className="hidden items-center lg:flex"
          >
            Compensation
          </SavedRolesDataTableHeader>
        );
      },
      cell: ({ getValue }) => {
        const { compensation, payType } = getValue();

        return (
          <TableCell className="hidden lg:table-cell">
            <Comp comp={compensation} payType={payType} />
          </TableCell>
        );
      },
    }
  ),
  columnHelper.accessor(
    (row) => ({
      notes: row.notes,
      id: row.id,
      type: row.type,
    }),
    {
      id: 'notes',
      header: () => {
        return (
          <TableHead className="hidden items-center lg:flex">
            <span className="inline-block pl-2">Notes</span>
          </TableHead>
        );
      },
      cell: ({ getValue }) => {
        const { id, notes } = getValue();

        if (notes == null || notes.length <= 0) {
          return (
            <TableCell className="hidden lg:table-cell">
              <EditSavedRoleNotesDialog notes={notes ?? ''} savedRoleId={id}>
                <NotesCellButton state="empty">
                  <NotesCellContentLayout>Add Note</NotesCellContentLayout>
                </NotesCellButton>
              </EditSavedRoleNotesDialog>
            </TableCell>
          );
        }

        return (
          <TableCell className="hidden lg:table-cell">
            <EditSavedRoleNotesDialog notes={notes ?? ''} savedRoleId={id}>
              <NotesCellButton state="filled">
                <NotesCellContentLayout>{notes}</NotesCellContentLayout>
              </NotesCellButton>
            </EditSavedRoleNotesDialog>
          </TableCell>
        );
      },
    }
  ),
  columnHelper.accessor(
    (row) => ({
      id: row.id,
      rank: row.rank,
      type: row.type,
    }),
    {
      id: 'rank',
      header: () => {
        return (
          <SavedRolesDataTableHeader
            orderById="rank"
            className="items-center lg:flex"
          >
            Rank
          </SavedRolesDataTableHeader>
        );
      },
      cell: ({ getValue }) => {
        const { id: savedRoleId, rank } = getValue();

        return <RankCell key={rank} rank={rank} savedRoleId={savedRoleId} />;
      },
    }
  ),
  columnHelper.accessor((row) => row, {
    id: 'actions',
    header: () => {
      return (
        <TableHead>
          <span className="sr-only">Actions</span>
        </TableHead>
      );
    },
    cell: ({ getValue }) => {
      const row = getValue();

      return (
        <TableCell className="justify-self-center lg:justify-self-end">
          <SavedRolesRowActions row={row} />
        </TableCell>
      );
    },
  }),
];

function RoleCellLayout({
  isRemote,
  type,
  company,
  compensation,
  payType,
  children,
}: {
  isRemote: boolean;
  type: RoleType;
  company: string;
  payType?: PayType;
  compensation?: number;
  children?: React.ReactNode;
}) {
  return (
    <TableCell>
      <span className="linkBox relative flex w-fit flex-col gap-1">
        <span className="inline-flex shrink-0 flex-wrap items-baseline gap-x-3 gap-y-1">
          {children}
          <span className="flex gap-1">
            {isRemote && (
              <Badge
                size="sm"
                colorScheme="blue"
                icon={
                  <Icon>
                    <IconUse id="base-station-fill" />
                  </Icon>
                }
              >
                Remote
              </Badge>
            )}
            {type === 'external' && (
              <Badge
                size="sm"
                colorScheme="purple"
                icon={
                  <Icon>
                    <IconUse id="external-link-line" />
                  </Icon>
                }
              >
                External
              </Badge>
            )}
          </span>
        </span>
        <span
          className={text({
            variant: '14',
            color: 'secondary',
            className: 'line-clamp-2 flex-1',
          })}
        >
          {company}
        </span>
        <span className="block pt-1 text-xs text-ds-text-tertiary lg:hidden">
          <Comp comp={compensation} payType={payType} />
        </span>
      </span>
    </TableCell>
  );
}

const NotesCellButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<'button'> &
    VariantProps<typeof notesCellButtonStyles>
>(({ state, children, className, ...rest }, ref) => {
  const styles = notesCellButtonStyles({ state, className });

  return (
    <button ref={ref} className={styles} {...rest}>
      {children}
    </button>
  );
});
NotesCellButton.displayName = 'NotesCellButton';

const notesCellButtonStyles = tv({
  extend: focusRingStyles,
  base: 'transition-colors hover:bg-ds-bg-weaker active:bg-ds-bg-soft rounded-md px-2 py-1 flex items-center justify-center w-full lg:w-auto lg:justify-start',
  variants: {
    state: {
      empty: 'text-ds-text-tertiary hover:text-ds-text-primary',
      filled: 'text-ds-text-secondary hover:text-ds-text-primary',
    },
  },
  defaultVariants: {
    state: 'empty',
  },
});

function NotesCellContentLayout({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <span className={twMerge('flex gap-2', className)}>
      <span className="flex h-5 items-center">
        <Icon className="h-4 w-4 shrink-0 text-current" aria-hidden>
          <IconUse id="file-text-line" />
        </Icon>
      </span>
      <span className="line-clamp-2 hidden text-ellipsis text-left text-current lg:[display:-webkit-box]">
        {children}
      </span>
    </span>
  );
}

function Comp({ comp, payType }: { comp?: number; payType?: PayType }) {
  if (!comp) return null;

  function getPayTypeLabel(payType?: PayType) {
    if (!payType) return '';
    const payTypeLabel = {
      Salary: '/yr',
      Hourly: '/hr',
    } as const;

    return payTypeLabel[payType];
  }

  function getFormattedComp(comp: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(comp);
  }

  return (
    <span className="whitespace-nowrap">
      {getFormattedComp(comp)}{' '}
      <span className="text-ds-text-tertiary">{getPayTypeLabel(payType)}</span>
    </span>
  );
}

function RankCell({
  rank,
  savedRoleId,
}: {
  rank?: RankType;
  savedRoleId: number;
}) {
  const mutation = useUpdateSavedRole(savedRoleId);

  return (
    <TableCell>
      <Select
        defaultValue={rank}
        onValueChange={(value: RankType | 'None') => {
          toast.promise(
            mutation.mutateAsync({
              rank: value === 'None' ? null : value,
            }),
            {
              loading: 'Updating rank...',
              success: 'Successfully updated rank',
              error: 'Failed to update rank',
            }
          );
        }}
        disabled={mutation.isPending}
      >
        <SelectPrimitive.Trigger asChild>
          <Button svgOnly variant="ghost">
            <Rank aria-hidden level={rank} />
            <span className="sr-only">Change rank from "{rank ?? 'None'}"</span>
          </Button>
        </SelectPrimitive.Trigger>

        <SelectContent className="w-[180px] p-1" side="left" align="start">
          <SelectGroup>
            {RANK_ITEMS.map((rank) => {
              const level = rank === 'None' ? undefined : rank;

              return (
                <SelectItem key={rank} value={rank}>
                  <span className="flex gap-2">
                    <Rank level={level} />
                    <span>{rank}</span>
                  </span>
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
    </TableCell>
  );
}

function SavedRolesDataTableHeader({
  orderById,
  className,
  children,
}: {
  orderById: string;
  className?: string;
  children?: React.ReactNode;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderDir: currentOrderDir, orderBy: currentOrderBy } =
    getSavedSearchParams(searchParams);
  const isCurrentOrderBy = currentOrderBy === orderById;

  function handleDirectionChange(orderDir: OrderDirection | null) {
    setSearchParams((searchParams) => {
      if (orderDir === null) {
        searchParams.delete(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_BY);
        searchParams.delete(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_DIR);
        return searchParams;
      }

      searchParams.set(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_BY, orderById);
      searchParams.set(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_DIR, orderDir);

      return searchParams;
    });
  }

  if (!isCurrentOrderBy) {
    return (
      <SortableColumnHeader
        onDirectionChange={handleDirectionChange}
        orderDir={null}
        className={className}
      >
        {children}
      </SortableColumnHeader>
    );
  }

  return (
    <SortableColumnHeader
      onDirectionChange={handleDirectionChange}
      orderDir={currentOrderDir}
      className={className}
    >
      {children}
    </SortableColumnHeader>
  );
}

const RANK_ITEMS = ['None', ...RANKS] as const;
